import React from 'react';

class RefNrField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toValue = this.toValue.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value: "",
            isValid: false
        };
    }

    toValue(v) {
        v = v.toUpperCase();
        if( v.match(/^([A-Z]{1,3}[A-Z0-9]{0,2}\d{0,6}|COMAIL)?$/)) {
            return v;
        }
        return false;
    }

    onBlur(e) {
        const v = e.target.value.toUpperCase();

        const isValid = v.match(/^([A-Z]{3}[A-Z0-9]{2}\d{5}|COMAIL)?$/) !== null;
        this.setState({isValid: isValid});

    }

    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        const newValue = this.toValue(e.target.value);
        if( newValue === false ) {
            return;
        }
        e.target.value = newValue;

        this.props.handleInputChange(e);
    }

    render() {
        const referenzNummer = this.props.referenzNummer;
        const airline = this.props.airline;
        const className = "field-name valid-" + this.state.isValid;
        const disabled = !airline || !airline.hatReferenznummer;
        return (
            <div className="form-item refnr-field">
                <label className={className}>RefNr<br/>
                    <input value={referenzNummer}
                           ref={this.props.referrer}
                           name="referenzNummer"
                           size="15"
                           maxLength="10"
                           disabled={disabled}
                           onChange={this.handleInputChange}
                           onKeyDown={this.props.onKeyDown}
                           onBlur={this.onBlur}
                           placeholder="RefNr">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default RefNrField;

class AirlineValidator {
    validateInputs(inputData, airlines) {
        let errorMsg = "";

        // Es muss eine eindeutige Kundennummer angegeben sein
        if (!inputData.kundennummer) {
            errorMsg += "Bitte eine Kundennummer angeben";
        } else {
            if( airlines.findIndex(el => el.kundennummer === parseInt(inputData.kundennummer)) >= 0) {
                errorMsg += "Die Kundennummer wird bereits verwendet.";
            }
        }

        // Es muss ein eindeutiges Kürzel angegeben sein
        if (!inputData.kuerzel) {
            errorMsg += "Bitte ein Kürzel angeben.";
        } else {
            if( airlines.findIndex(el => el.kuerzel === inputData.kuerzel) >= 0 ) {
                alert('Es gibt bereits eine Airline mit dem Kürzel ' + inputData.kuerzel);
                return;
            }
        }


        if (errorMsg.length === 0) {
            return true;
        } else {
            alert(errorMsg);
            return false;
        }
    }
}

export default AirlineValidator;
import React from 'react';
import AuftragService from '../shared/AuftragService.js';
import AuftragForm from './../forms/AuftragForm/AuftragForm.js';
import GridFormatter from '../shared/GridFormatter.js';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community"
import ActionsRenderer from './ActionsRenderer.js';



class AuftragGrid extends React.Component {

    constructor(props) {
        super(props);
        this.auftragService = new AuftragService();
        this.gridFormatter = new GridFormatter();
        this.getAuftraege = this.getAuftraege.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.onUpdateFilterAttributes = this.onUpdateFilterAttributes.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.onSumGridReady = this.onSumGridReady.bind(this);
        this.handleDeleteAuftrag = this.handleDeleteAuftrag.bind(this);
        this.applyGridFilter = this.applyGridFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.createState = this.createState.bind(this);
        this.updateSummenzeile = this.updateSummenzeile.bind(this);
        this.stopEditing = this.stopEditing.bind(this);

        this.bottomData = [{
            id: "",
            fgsBilo: "",
            airline: {kuerzel: ""},
            auftragsdatum: {date: ""},
            art: {name: ""},
            referenzNummer: "",
            stueck: "",
            km: "",
            sammelNachttaxi: "",
            direkt: "",
            post: "",
            nebenkosten: "",
            porto: "",
            endsumme: ""
        }];
        this.state = this.createState();
    }

    createState() {
        const topOptions = {alignedGrids: [], suppressHorizontalScroll: true};
        const bottomOptions = {alignedGrids: []};

        topOptions.alignedGrids.push(bottomOptions);
        bottomOptions.alignedGrids.push(topOptions);

        return {
            topOptions,
            bottomOptions,
            bottomData: this.bottomData,
            error: null,
            isLoaded: false,
            auftraege: [],
            filteredAuftraege: [],
            itemToEdit: null,
            azr: null,
            firmaFilter: "",
            airlineFilter: null,
            checkSum: 0,
            filterActiv: false,
            modules: AllCommunityModules,
            columnDefs: [
                {headerName: "lfdNr",
                    field: "id",
                    sort: "asc",
                    width: 60},
                {headerName: "F/B",
                    field: "fgsBilo",
                    width: 40},
                {headerName: "Airline",
                    field: "airline.kuerzel",
                    width: 50},
                {headerName: "Datum",
                    field: "auftragsdatum.date",
                    width: 80,
                    valueFormatter: this.gridFormatter.dateFormatter},
                {headerName: "Art",
                    field: "art.name",
                    width: 45},
                {headerName: "Ref-Nr",
                    field: "referenzNummer",
                    width: 100},
                {headerName: "Stück",
                    field: "stueck",
                    width:50},
                {headerName: "Km",
                    field: "km",
                    width: 50},
                {headerName: "Sammel",
                    field: "sammelNachttaxi",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName: "Direkt",
                    field: "direkt",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName: "Post",
                    field: "post",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName: "N.kosten",
                    field: "nebenkosten",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName: "Porto",
                    field: "porto",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName: "Summe",
                    field: "endsumme",
                    cellClass: "betrag",
                    valueFormatter: this.gridFormatter.currencyFormatter,
                    flex: 1},
                {headerName:
                    "Aktionen",
                    field: "id",
                    width: 60,
                    cellRenderer: "actionsRenderer" }

            ],
            context: { componentParent: this },
            frameworkComponents:{
                actionsRenderer: ActionsRenderer
            },
            getRowNodeId: function(data) {
                return data.id;
            }
        };
    }

    componentDidMount() {
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getAuftraege(this.props.azr.id);
        }

    }

    componentDidUpdate(preProps) {
        if (!this.state.azr && this.props.azr) {
            this.setState({azr: this.props.azr});
        }
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getAuftraege(this.props.azr.id);
        }
    }


    getAuftraege(azr) {
        this.auftragService.retrieveItems(azr).then(auftraege => {
            this.setState({auftraege: auftraege, filteredAuftraege: auftraege, isLoaded: true});
            // this.updateSummenzeile(auftraege);
        });
    }

    onCreate(auftrag) {
        var auftraege;
        // var filteredAuftraege;
        // console.log('onCreate ' + auftrag);
        this.auftragService.createItem(auftrag)
            .then(auftrag => {
                auftraege = this.state.auftraege;
                auftraege.push(auftrag);
//                filteredAuftraege = this.state.filteredAuftraege;
                // filteredAuftraege.push(auftrag);
                this.setState({auftraege: auftraege});
                this.refs.AuftragGrid.api.updateRowData(
                    {
                        add: [ auftrag ]
                    }
                );
                this.updateSummenzeile();
                this.scrollToBottom();
                // this.onEditChange(auftrag.fgsBilo, auftrag.endsumme);
            });
    }

    onUpdate(auftrag) {
        this.auftragService.updateItem(auftrag)
            .then(auftrag => {
                this.gridApi.updateRowData(
                    {
                        update: [ auftrag ]
                    }
                );
                /*
                // die Redraw-Methode muss hier manuell aufgerufen werden,
                // da die setData-Methode offenbar den CellRenderer
                // für den ActionsRenderer nicht nochmal aufruft.
                // Dieser sitzt dann auf alten Daten und beim erneuten
                // Klick auf Bearbeiten erhält man die alten Daten wieder
                // ins Bearbeiten-Formular.
                */
                this.gridApi.redrawRows();
                this.updateSummenzeile();
            });

    }

    onUpdateFilterAttributes(firma, airline) {
        this.setState({firmaFilter: firma, airlineFilter: airline});
        if( this.state.filterActiv ) {
            this.applyGridFilter(firma, airline);
        }
    }

    toggleFilter() {
        //console.log('toggleFilter');
        const filterActiv = !this.state.filterActiv;
        this.setState({filterActiv: filterActiv});

        // console.log('isFiltered ' + filterActiv);

        if( filterActiv ) {
            this.applyGridFilter();
            return;
        }
        this.clearFilter();
    }

    updateSummenzeile() {
        function summeReducer (total, auftrag) {

            const fields = ['stueck', 'km', 'sammelNachttaxi', 'direkt', 'post', 'nebenkosten', 'porto', 'endsumme'];

            var summen = {};
            var floatValAuftrag;
            var floatValTotal;
            var floatValSumme;

            fields.forEach(field => {
                floatValAuftrag = 0;
                floatValTotal = 0;

                if( auftrag.data && auftrag.data[field] ) {
                    floatValAuftrag = parseFloat(auftrag.data[field]);
                }

                // Die Variable total ist der Aggregator des Reducers.
                // Im ersten Lauf des Reducers enthält total das erste
                // Element des Arrays aus RowNode-Objekten.
                // Seine Daten werden denen des erstenaddiert.
                //
                // Ab dem zweiten Durchlauf enthalt total ein reduziertes
                // Objekt, in dem nur noch die aggregierten Werte stehen,
                // und nicht mehr das komplette RowNode-Objekt.
                //
                // Daher muss der Bezug der Werte hier flexiebl sein, und
                // sowohl mit dem RowNode-Objekt funktionieren (hat die
                // Nutzwerte im Sub-Objekt data), als auch mit dem aggregierten
                // Objekt (enthält die Nutzwerte direkt).
                if( total.data && total.data[field] ) {
                    floatValTotal = parseFloat(total.data[field]);
                } else if ( !total.data && total[field] ) {
                    floatValTotal = parseFloat(total[field]);
                }
                floatValSumme = Math.round((floatValTotal + floatValAuftrag)*100)/100;
                summen[field] = floatValSumme;
            });
            return summen;
        }
        var summenzeile = this.bottomData;

        // var rows = this.gridApi.rowModel.rootNode.allLeafChildren;
        //if( this.gridApi.rowModel.rootNode.childrenAfterFilter ) {
            var rows = this.gridApi.rowModel.rootNode.childrenAfterFilter;
        //}

        // Wenn das Array nur ein Element hat, dann liefert der reducer
        // einfach das unveränderte, eine Array-Element zurück.
        // Das müssen wir vermeiden - Probleme siehe unten.
        if( rows.length === 1) {
            // Javascript macht bei einer einfachen Variablenzuweisung von Objekten
            // einfach nur einen Pointer auf das Ursprungsobjekt.
            // Somit würde "summenzeile = rows[0].data", mit anschließender
            // Änderung der summenzeile-Members, auch rows[0].data geändert
            // und der Datensatz somit verfälscht.
            // Daher muss die Zuweisung über einen Objekt-Klon erfolgen.
            //
            // Bestätigt über stackoverflow:
            // If you use an = statement to assign a value to a var with an object
            // on the right side, javascript will not copy but reference the object.
            summenzeile = Object.assign({}, rows[0].data);
            summenzeile.id = "";
        }
        if( rows.length > 1 ) {
            summenzeile = rows.reduce(summeReducer);
            summenzeile.referenzNummer = rows.length;
        }

        if( rows.length < this.gridApi.rowModel.rootNode.allLeafChildren.length ) {
            summenzeile.fgsBilo = rows[0].data.fgsBilo;
            summenzeile.airline = rows[0].data.airline;
        }

        var rowNode = this.sumGridApi.getDisplayedRowAtIndex(0);
        rowNode.setData(summenzeile);
    }

    handleDeleteAuftrag(auftrag) {
        if( auftrag && auftrag.id ) {
            this.auftragService.deleteItem(auftrag.id)
                .then(response => {
                    if( response.success ) {
                        var auftraege = this.state.auftraege;
                        auftraege.splice(auftraege.indexOf(auftrag),1);
                        this.setState({auftraege:auftraege});

                        this.refs.AuftragGrid.api.updateRowData(
                            {
                                remove: [ auftrag ]
                            }
                        );

                    }
                });
        }
    }

    handleEditAuftrag(auftrag) {
        // console.log('handleEditAuftrag parent');
        // console.log(auftrag);
        this.setState({itemToEdit: auftrag});
    }

    stopEditing() {
        this.setState({itemToEdit: null});
    }


    onFirstDataRendered(params) {
        this.scrollToBottom();
        this.updateSummenzeile(null);
    }

    onGridReady = params => {
        this.gridApi = params.api;

    };

    onSumGridReady = params => {
        this.sumGridApi = params.api;
    };

    scrollToBottom() {
        var bottomRow = this.gridApi.rowModel.rootNode.allLeafChildren.length -1;
        if( this.state.filterActiv ) {
            bottomRow = this.gridApi.rowModel.rootNode.childrenAfterFilter.length -1;
        }
        this.gridApi.ensureIndexVisible(bottomRow, 'bottom');
    }

    applyGridFilter(firma = null, airline = null) {

        airline = airline !== null ? airline : this.state.airlineFilter;
        firma = firma !== null ? firma : this.state.firmaFilter;

        if( !airline || !firma ) {
            // this.clearFilter();
            return;
        }

        var airlineFilterComponent = this.gridApi.getFilterInstance('airline.kuerzel');
        var model = {
            filterType: "text",
            type: "equals",
            filter: airline.kuerzel
        };
        airlineFilterComponent.setModel(model);

        var firmaFilterComponent = this.gridApi.getFilterInstance('fgsBilo');
        var firmaModel = {
            filterType: "text",
            type: "equals",
            filter: firma
        };
        firmaFilterComponent.setModel(firmaModel);
        /*
        filterType: "text"
        type: ["equals", "notEqual", "contains", "notContains", "startsWith", "endsWith"]
        */

        var filteredAuftraege = this.state.auftraege;
        if( Array.isArray(filteredAuftraege)) {
            filteredAuftraege = filteredAuftraege.filter(el => el.fgsBilo === firma && el.airline.kuerzel === airline.kuerzel);
            this.setState({filteredAuftraege: filteredAuftraege});
        }
        this.gridApi.onFilterChanged();
        this.setState({filterActiv: true});
        this.updateSummenzeile(filteredAuftraege);
        // console.log(this.gridApi);
    }

    clearFilter() {
        var airlineFilterComponent = this.gridApi.getFilterInstance('airline.kuerzel');
        var firmaFilterComponent = this.gridApi.getFilterInstance('fgsBilo');

        firmaFilterComponent.setModel(null);
        airlineFilterComponent.setModel(null);
        this.gridApi.onFilterChanged();
        this.setState({filterActiv: false});

        this.updateSummenzeile(this.state.auftraege);
        this.scrollToBottom();
    }

    render() {
        const selectedAzr = this.state.azr;


            // @todo: Spalten deklarativ definieren, nicht per columnDef im State
            // -> https://www.ag-grid.com/react-column-configuration/
        return (
            <div className="fgs-tab-panel fgs-auftraege-panel"
                style={{overflow:'hidden', flexGrow: 1}}>
                { /*
                <button onClick={this.clearFilter.bind(this)}>Filter löschen</button>
                */ }
                <div style={{height:'100%'}} className="ag-theme-blue">
                    <AgGridReact
                        ref="AuftragGrid"
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.auftraege}
                        context={this.state.context}
                        rowSelection="single"
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        getRowNodeId={this.state.getRowNodeId}
                        onGridReady={this.onGridReady}>
                    </AgGridReact>
                </div>

                <div className="ag-theme-blue" style={{height: '30px'}}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.bottomData}
                        headerHeight="0"
                        onGridReady={this.onSumGridReady}
                        >
                    </AgGridReact>
                </div>


                { selectedAzr &&
                <div className="fgs-auftrag-footer">
                    <div className="inner-panel">
                        <AuftragForm
                            itemToEdit={this.state.itemToEdit}
                            azr={selectedAzr}
                            context={this.state.context}
                            onCreate={this.onCreate}
                            onUpdate={this.onUpdate}
                            onUpdateFilterAttributes={this.onUpdateFilterAttributes}
                            onToggleFilter={this.toggleFilter}
                            clearFilter={this.clearFilter}
                            stopEditing={this.stopEditing}
                            />
                    </div>
                </div>
                }


            </div>
        );
    }
}

export default AuftragGrid;
import React from 'react';

class PortoField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    handleInputChange(e, autoporto = false) {
        // nur lokal validieren und ggf. umformatieren
        e.target.value = this.toValue(e.target.value);
        this.props.handleInputChange(e, autoporto);
    }

    handleBlur(e) {
        // this.props.handleBlur(e);
    }

    onKeyDown(e) {

        if( e.key.match(/^[0-9,.\-*/]|ArrowLeft|Left|ArrowRight|Right|Enter|Backspace|Tab|Delete|Del$/) ) {
            if( this.props.autoporto && e.key.match(/[0-9,.]/)) {
                e.target.value = "";
            }
            this.props.onKeyDown(e);
            return;
        }
        e.preventDefault();
    }

    onKeyUp(e) {
        const key = e.key;
        //Backspace und Delete löschen gesamten Feldinhalt
        if( key === 'Backspace' || key === 'Delete') {
            e.target.value="";
            this.handleInputChange(e, true);
        }
    }

    toDisplayValue(v) {
        if ( v ) {
            return v.toString().replace('.', ',');
        }
        return v;
    }

    toValue(dv) {
        if( dv ) {
            return dv.toString().replace(',', '.');
        }
        return dv;
    }

    render() {
        const porto = this.props.value;
        const displayValue = this.toDisplayValue(porto);
        const name = "porto";
        const art = this.props.art;
        const fees = this.props.fees;
        const autoporto = this.props.autoporto.toString();
        const className = 'form-item porto-field autoporto-' + autoporto;
        const modeLabel = this.props.autoporto ? 'auto' : 'man';
        var disabled = false;
        var readOnly = false;
        if (art) {
            var auftragFieldList = art.fieldList.split(/\s*,\s*/);
            disabled = auftragFieldList.indexOf(name) < 0;

            if (fees) {
                var fee = fees.find(el => el.art.id === art.id);
                var calcFields = [fee.per_order_field, fee.per_piece_field, fee.per_km_field];
                readOnly = calcFields.indexOf(name) >= 0;
            }
        }

        return (
            <div className={className}>
                <label className="field-name">Porto {modeLabel}<br/>
                    <input value={displayValue}
                           name={name}
                           size="8"
                           maxLength="8"
                           onChange={this.handleInputChange}
                           onKeyUp={this.onKeyUp}
                           onKeyDown={this.onKeyDown}
                           disabled={disabled}
                           readOnly={readOnly}
                           ref={this.props.referrer}
                           placeholder="Porto">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default PortoField;

import React from 'react';
import AzrValidator from './../shared/AzrValidator.js';
// import AzrForm from './../forms/AzrForm.js';

class NewAzr extends React.Component {

    constructor(props) {
        super(props);
        this.validator = new AzrValidator();
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            datum: "",
            gesamt: ""
        }

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    onCancel() {
        this.props.onCancel();
    }

    onSubmit() {
        if( this.validator.validateInputs(this.state)) {
            this.props.onSubmit(this.state);
        }
    }

    render() {
        return (
            <div className="input-panel">
                <span className="form-caption">Neuer Azr: </span>
                { /*
                 <AzrForm itemToEdit={this.state} handleInputChange={this.handleInputChange} />
                 */ }

                <div>
                    <label className="field-name">Datum:<br/>
                        <input value={this.state.datum}
                               name="datum"
                               required
                               onChange={this.handleInputChange}
                               placeholder="Datum" />
                    </label>
                </div>
                {/*
                <div>
                    <label className="field-name">Gesamt:<br/>
                        <input value={this.state.gesamt}
                               name="gesamt"
                               onChange={this.handleInputChange}
                               placeholder="Gesamtbetrag" />
                    </label>
                </div>
                 */}
                <br/>

                <button onClick={() => this.onCancel()}>Abbrechen</button>
                <button onClick={() => this.onSubmit()}>Erstellen</button>
            </div>
        );
    }

}

export default NewAzr;
// https://dzone.com/articles/consuming-rest-api-with-reactjs


// import Configuration from './../Configuration.js';
import BaseService from "./BaseService";

class FeeService extends BaseService {

    // eslint-disable-next-line
    constructor() {
        super();
    }

    async getStandardFees() {
        return fetch(this.config.FEE_BASE + 'standard.json')
            .then( response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then( json => {
                return json.fees;
            })
            .catch(error => {
                this.handleError(error);
            }
        );
    }

    async updateFee(item) {
        const body = {fee: item};

        return fetch(this.config.FEE_BASE + 'edit.json', {
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .catch(error => {
            this.handleError(error);
        });

    }


/*
    handleResponseError(response) {
        console.log('handleResponseError - response.status = ' + response.status);
        if( response.status === 401 ) {
            console.log('Führe das Logout durch.');
            const userService = new UserService();
            userService.logoutUser();
            window.location.reload();
            return
        }
        throw new Error("HTTP error, status = " + response.status);
    }

 */
    handleError(error) {
        console.log(error.message);
    }

}

export default FeeService;
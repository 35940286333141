// https://dzone.com/articles/consuming-rest-api-with-reactjs

// todo: CORS Preflight-Listener in Symfony einbauen
// https://www.upbeatproductions.com/blog/cors-pre-flight-requests-and-headers-symfony-httpkernel-component

// import Configuration from './../Configuration.js';
import BaseService from "./BaseService";

class StatisticService extends BaseService {

    // eslint-disable-next-line
    constructor() {
        super();
        // this.config = new Configuration();
    }

    async retrieveItems(azr) {

        return fetch(this.config.STATS_BASE + 'list.json?azrId=' + azr)
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.statistics;
            })
            .catch(error => {
                this.handleError(error)
            })
    }

    getUrl2Pdf(itemId) {
        return ( this.config.STATS_BASE + 'show.pdf?id=' + itemId );
    }


    /*
     async getItem(id) {
     console.log("ItemService.getItem():");
     console.log("Item: " + id);

     return fetch(this.config.AZR_BASE + 'show/' + id + '.json')
     .then( response => {
     if (!response.ok) {
     this.handleResponseError(response);
     }
     return response.json();
     })
     .then( json => {
     return json.item;
     })
     .catch(error => {
     this.handleError(error);
     }
     );
     }

     async createItem(item) {
     console.log("ItemService.createItem():");
     console.log("Item: " + item);
     const body = {"item": item};
     return fetch(this.config.AUFTRAG_BASE + 'create.json', {
     method: 'post',
     body: JSON.stringify(body)
     })
     .then(response => {
     if(!response.ok) {
     this.handleResponseError(response);
     }
     return response.json();
     })
     .then( json => {
     return json.item;
     })
     .catch(error => {
     this.handleError(error);
     });

     }

     async deleteItem(id) {
     console.log("AuftragService.deleteItem():");
     console.log("Item ID: " + id);

     return fetch(this.config.AZR_BASE + id + '/delete.json')
     .then(response => {
     if(!response.ok) {
     this.handleResponseError(response);
     }
     return response.json();
     })
     .catch(error => {
     this.handleError(error);
     });

     }

     async updateItem(item) {
     console.log("AuftragService.updateItem():");
     console.log(item);

     const body = {"item": {
     "id": item.id,
     "mwst": item.mwst,
     "rueckstellungLh": item.rueckstellungLh}
     };

     return fetch(this.config.AIRLINE_BASE + 'edit.json', {
     method: "POST",
     body: JSON.stringify(body)
     })
     .then(response => {
     if (!response.ok) {
     this.handleResponseError(response);
     }
     return response.json();
     })
     .catch(error => {
     this.handleError(error);
     });

     }

     */

    handleError(error) {
        console.log(error.message);
    }

}

export default StatisticService;
class AuftragValidator {

    validateInputs(inputData) {
        let errorMsg = "";

        if (!inputData.azr) {
            errorMsg += "Bitte den AZR angeben.";
        }

        if (!inputData.airline) {
            errorMsg += "Bitte die Airline angeben.";
        }

        if( !inputData.referenzNummer.match(/^([A-Z]{3}[A-Z0-9]{2}\d{5}|COMAIL)?$/)) {
            errorMsg += "Die Referenznummer entspricht nicht dem vorgegebenen Format.";
        }

        if (!inputData.stueck) {
            errorMsg += "Bitte die Stückzahl angeben.";
        }

        if (isNaN(inputData.endsumme)) {
            errorMsg += "Bitte eine Summe eingeben.";
        }

        if (errorMsg.length === 0) {
            return true;
        } else {
            alert(errorMsg);
            return false;
        }
    }
}

export default AuftragValidator;

class Configuration {

    API_URL = process.env.REACT_APP_API_BASE;
    APP_VERSION = process.env.REACT_APP_VERSION;
    APP_BUILD = process.env.REACT_APP_BUILD;

    AZR_BASE = this.API_URL + 'api/azr/';
    AIRLINE_BASE = this.API_URL + 'api/airline/';
    AUFTRAG_BASE = this.API_URL + 'api/auftrag/';
    INVOICE_BASE = this.API_URL + 'api/invoice/';
    STATS_BASE = this.API_URL + 'api/statistic/';
    FEE_BASE = this.API_URL + 'api/fee/';
}

export default Configuration;

class GridFormatter {

    currencyFormatter(params) {
        if (!params.value || params.value === 0) {
            return "";
        }
        return new Intl.NumberFormat("de-DE", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(params.value);
    }

    dateFormatter(params) {
        if (!params.value) {
            return "";
        }

        const datum = new Date(params.value);
        const day = datum.getDate() < 10 ? '0' + datum.getDate() : datum.getDate();
        var month = datum.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        return day + '.' + month + '.' + datum.getFullYear();
    }
}

export default GridFormatter;
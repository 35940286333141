import React from 'react';

export default function UserControlhandle({Logout, User}) {

    return(
        <div className="user">
            <p className="username">Angemeldet als <br/>
                {User.firstname} {User.lastname}
            </p>
            <button className="logout" onClick={Logout}>
                Logout
            </button>
        </div>
    );
}
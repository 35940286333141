import React from 'react';

class NebenkostenField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        e.target.value = this.toValue(e.target.value);
        this.props.handleInputChange(e);
    }

    handleBlur(e) {
        this.props.handleBlur(e);
    }

    onKeyDown(e) {

        if( e.key.match(/^[0-9,.\-*/]|ArrowLeft|Left|ArrowRight|Right|Enter|Backspace|Tab|Delete|Del$/) ) {
            this.props.onKeyDown(e);
            return;
        }
        e.preventDefault();
    }

    toDisplayValue(v) {
        if ( v ) {
            return v.toString().replace('.', ',');
        }
        return v;
    }

    toValue(dv) {
        if( dv ) {
            return dv.toString().replace(',', '.');
        }
    }

    render() {
        const nebenkosten = this.props.value;
        const displayValue = this.toDisplayValue(nebenkosten);
        // const name = "nebenkosten";

        return (
            <div className="form-item nebenkosten-field">
                <label className="field-name">N.kosten<br/>
                    <input value={displayValue}
                           ref={this.props.referrer}
                           size="8"
                           maxLength="8"
                           name="nebenkosten"
                           onChange={this.handleInputChange}
                           onBlur={this.handleBlur}
                           onKeyDown={this.onKeyDown}
                           placeholder="N.kosten">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default NebenkostenField;

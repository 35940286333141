import React from 'react';

class AuftragPostenField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        e.target.value = this.toValue(e.target.value);
        this.props.handleInputChange(e);
    }

    onKeyDown(e) {
        if( e.key.match(/^[0-9,.\-*/]|ArrowLeft|Left|ArrowRight|Right|Enter|Backspace|Tab|Delete|Del$/) ) {
            this.props.onKeyDown(e);
            return;
        }
        e.preventDefault();
    }

    handleBlur(e) {
        this.props.handleBlur(e);
    }

    toDisplayValue(v) {
        if ( v ) {
            return v.toString().replace('.', ',');
        }
        return v;
    }

    toValue(dv) {
        if( dv ) {
            return dv.toString().replace(',', '.');
        }
        return dv;
    }



    render() {
        const value = this.props.value;
        const displayValue = this.toDisplayValue(value);
        const name = this.props.name;
        const label = this.props.label;
        const art = this.props.art;
        const fees = this.props.fees;
        const className = 'form-item ' + name + '-field';
        var disabled = false;
        var readOnly = false;

        // Das Nebenkostenfeld ist IMMER enabled und IMMER schreibbar
        if (art) {
            var auftragFieldList = art.fieldList.split(/\s*,\s*/);
            disabled = auftragFieldList.indexOf(name) < 0;

            if (fees) {
                const fee = fees.find(el => el.art.id === art.id);
                const calcFields = [fee.per_order_field, fee.per_piece_field, fee.per_km_field];
                readOnly = calcFields.indexOf(name) >= 0;
            }
        }

        return (
            <div className={className}>
                <label className="field-name">{label}<br/>
                    <input
                           ref={this.props.referrer}
                           value={displayValue}
                           name={name}
                           size="8"
                           maxLength="8"
                           onKeyDown={this.onKeyDown}
                           onChange={this.handleInputChange}
                           onBlur={this.handleBlur}
                           disabled={disabled}
                           readOnly={readOnly}
                           placeholder={label}>
                    </input>
                </label>
            </div>
        )


    }

;

}

export default AuftragPostenField;

import React, {Component} from "react";

class BooleanCheckRenderer extends Component {
    /*
    constructor(props) {
        super(props);

    }
*/

    render() {
        return(
            <span>
                { this.props.value &&
                    <i style={{color: 'green'}}  className="fa fa-check"></i>
                }
            </span>
        );
    }
}

export default BooleanCheckRenderer;
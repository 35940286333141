import React from 'react';

class StueckField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        this.props.handleInputChange(e);
    }

    handleBlur(e) {
        this.props.handleBlur(e);
    }

    render() {
        const stueck = this.props.stueck;
        return (
            <div className="form-item stueck-field">
                <label className="field-name">Stück<br/>
                    <input value={stueck}
                           ref={this.props.referrer}
                           name="stueck"
                           size="6"
                           maxLength="3"
                           onChange={this.handleInputChange}
                           onBlur={this.handleBlur}
                           onKeyDown={this.props.onKeyDown}
                           placeholder="Stück">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default StueckField;

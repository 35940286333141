// https://dzone.com/articles/consuming-rest-api-with-reactjs
// https://www.upbeatproductions.com/blog/cors-pre-flight-requests-and-headers-symfony-httpkernel-component

// import Configuration from './../Configuration.js';
import BaseService from "./BaseService";

class AuftragService extends BaseService {

    // eslint-disable-next-line
    constructor() {
        super();
        // this.config = new Configuration();
    }

    async retrieveItems(azr) {


        return fetch( this.config.AUFTRAG_BASE + 'listByAzr/'+azr+'.json')
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.auftraege;
            })
            .catch(error => {
                this.handleError(error)
            })
    }

    async retrieveAuftragsarten() {
        return fetch(this.config.AUFTRAG_BASE + 'art/list.json')
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.arten;
            })
            .catch(error => {
                this.handleError(error)
            })

    }

    /*
        async getItem(id) {
            console.log("ItemService.getItem():");
            console.log("Item: " + id);

            return fetch(this.config.AZR_BASE + 'show/' + id + '.json')
                .then( response => {
                    if (!response.ok) {
                        this.handleResponseError(response);
                    }
                    return response.json();
                })
                .then( json => {
                    return json.item;
                })
                .catch(error => {
                    this.handleError(error);
                }
            );
        }
    */
    async createItem(item) {
        console.log("ItemService.createItem():");
        console.log("Item: " + item);
        const body = {"auftrag": item};
        return fetch(this.config.AUFTRAG_BASE + 'add.json', {
            method: 'put',
            body: JSON.stringify(body)
        })
        .then(response => {
            if(!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .then( json => {
                return json.auftrag;
        })
        .catch(error => {
            this.handleError(error);
        });

    }

    async deleteItem(id) {
        console.log("AuftragService.deleteItem():");
        console.log("Item ID: " + id);

        const body = {"auftrag": {"id": id}};
        return fetch(this.config.AUFTRAG_BASE + 'delete.json',{
            method: 'delete',
            body: JSON.stringify(body)
        })
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });

    }

    async updateItem(item) {
        console.log("AuftragService.updateItem():");
        console.log(item);

        const body = {auftrag: item};
        return fetch(this.config.AUFTRAG_BASE + 'update.json', {
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .then(json => {
            return json.auftrag;
        })
        .catch(error => {
            this.handleError(error);
        });

    }

    /*
    handleResponseError(response) {
        throw new Error("HTTP error, status = " + response.status);
    }

     */

    handleError(error) {
        console.log(error.message);
    }

}

export default AuftragService;
import React, {Component} from "react";

class ActionsRenderer extends Component {
    constructor(props) {
        super(props);

        this.handleDeleteAuftrag = this.handleDeleteAuftrag.bind(this);
        this.handleEditAuftrag = this.handleEditAuftrag.bind(this);

    }

    handleDeleteAuftrag() {
        if (window.confirm("Wollen Sie den Auftrag wirklich löschen?")) {
            this.props.context.componentParent.handleDeleteAuftrag(this.props.data);
        }

    }

    handleEditAuftrag() {
        console.log('handleEditAuftrag');
        this.props.context.componentParent.handleEditAuftrag(this.props.data);
    }

    render() {
        return(
            <div>
                <button className="btn" onClick={this.handleDeleteAuftrag}><i className="fa fa-trash"></i></button>
                <button className="btn" onClick={this.handleEditAuftrag}><i className="fa fa-wrench"></i></button>
            </div>
        );
    }
}

export default ActionsRenderer;
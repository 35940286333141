import React from 'react';
import InvoiceService from '../shared/InvoiceService.js';

class InvoiceList extends React.Component {

    constructor(props) {
        super(props);
        this.invoiceService = new InvoiceService();
        this.state = {
            error: null,
            isLoaded: false,
            invoices: [],
            azr: null
        };
        this.getInvoices = this.getInvoices.bind(this);
    }

    componentDidMount() {
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getInvoices(this.props.azr.id);
        }
    }

    componentDidUpdate() {
        if (!this.state.azr && this.props.azr) {
            this.setState({azr: this.props.azr});
        }
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getInvoices(this.props.azr.id);
        }
    }


    getInvoices(azr) {
        this.invoiceService.retrieveItems(azr).then(items => {
            this.setState({invoices: items, isLoaded: true});
        });
    }

    handleDblClick(itemId) {
        window.open(this.invoiceService.getUrl2Pdf(itemId));
    }

    render() {
        const invoices = this.state.invoices;
        const listItems = invoices.map((invoice) =>
                <tr key={invoice.id} onDoubleClick={() => this.handleDblClick(invoice.id)}>
                    <th>{invoice.rechnungsnummerAsString}</th>
                    <td>{invoice.firma}</td>
                    <td>{invoice.airline.kundennummer}</td>
                    <td>{invoice.airline.kuerzel}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(invoice.fgsNetto)}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(invoice.fgsMwst)}</td>
                    <td className="betrag">{invoice.postNetto && new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(invoice.postNetto)}</td>
                    <td className="betrag">{invoice.postMwst && new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(invoice.postMwst)}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(invoice.gesamt)}</td>
                    <td>...</td>
                </tr>
        );


        /*
         if (!this.state.isLoaded) {
         itemList = <div>Loading</div>
         } else {
         itemList = <table><tbody>{listAzrs}</tbody></table>
         }*/

        return (
            <div className="fgs-tab-panel fgs-invoice-panel">
                <div className="fgs-invoice-list">

                    <table className="fixedHead">
                        <thead>
                        <tr>
                            <th>RNr</th>
                            <th>Firma</th>
                            <th>KuNr</th>
                            <th>Airline</th>
                            <th>FGS Netto</th>
                            <th>MwSt</th>
                            <th>Post Netto</th>
                            <th>Post MwSt</th>
                            <th>Gesamt</th>
                            <th>Kontrolle</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listItems}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }

}

export default InvoiceList;
import React from 'react';
import AzrValidator from './../shared/AzrValidator.js';
// import AzrForm from './../forms/AzrForm.js';

class EditAzr extends React.Component {

    constructor(props) {
        super(props);
        this.validator = new AzrValidator();
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        const itemToEdit = props.azr;

        this.state = {
            datum: itemToEdit.datum.date,
            mwst: itemToEdit.mwst,
            rueckstellungLh: itemToEdit.rueckstellungLh,
            gesamt: itemToEdit.gesamt,
            id: itemToEdit.id
        }

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    onCancel() {
        this.props.onCancel();
    }

    onSubmit() {
        if( this.validator.validateInputs(this.state)) {
            this.props.onSubmit(this.state);
        }
    }

    render() {
        return (
            <div className="input-panel">
                <span className="form-caption">Bearbeite Azr: {this.state.id}</span>
                { /*
                <AzrForm itemToEdit={this.state} handleInputChange={this.handleInputChange} />
                */ }

                <div>
                    <label className="field-name">Mehrwertsteuer:<br/>
                        <input value={this.state.mwst}
                               name="mwst"
                               required
                               onChange={this.handleInputChange}
                               placeholder="Mehrwertsteuer" />
                    </label>
                </div>
                <div>
                    <label className="field-name">Rückstellung LH:<br/>
                        <input value={this.state.rueckstellungLh}
                               name="rueckstellungLh"
                               onChange={this.handleInputChange}
                               placeholder="Rückstellung LH" />
                    </label>
                </div>
                <br/>

                <button onClick={() => this.onCancel()}>Abbrechen</button>
                <button onClick={() => this.onSubmit()}>Speichern</button>
            </div>
        );
    }

}

export default EditAzr;
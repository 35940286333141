// https://dzone.com/articles/consuming-rest-api-with-reactjs

// todo: CORS Preflight-Listener in Symfony einbauen
// https://www.upbeatproductions.com/blog/cors-pre-flight-requests-and-headers-symfony-httpkernel-component

import Configuration from './../Configuration.js';

class AirlineService {

    constructor() {
        this.config = new Configuration();
    }

    async retrieveItems(showActiveOnly = false) {
        var url = this.config.AIRLINE_BASE + 'list.json?active=' + showActiveOnly;


        // url.searchParams.append('active', showActiveOnly);
        return fetch( url,{
                method: 'get',
                credentials: 'include',
                mode: "no-cors",
                headers: new Headers({'Content-Type': 'application/json'})
            })
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.airlines;
            })
            .catch(error => {
                this.handleError(error)
            })
    }

    /*
        async getItem(id) {
            console.log("AirlineService.getItem():");
            console.log("Item: " + id);

            return fetch(this.config.AZR_BASE + 'show/' + id + '.json')
                .then( response => {
                    if (!response.ok) {
                        this.handleResponseError(response);
                    }
                    return response.json();
                })
                .then( json => {
                    return json.item;
                })
                .catch(error => {
                    this.handleError(error);
                }
            );
        }
    */
    async createItem(item) {
        console.log("AirlineService.createItem():");
        console.log("Item: " + item);
        const body = {"airline": item};
        return fetch(this.config.AIRLINE_BASE + 'create', {
            method: 'put',
            body: JSON.stringify(body)
        })
        .then(response => {
            if(!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .then( json => {
            return json.airline;
        })
        .catch(error => {
            this.handleError(error);
        });

    }
/*
    async deleteItem(id) {
        console.log("AirlineService.deleteItem():");
        console.log("Item ID: " + id);

        return fetch(this.config.AZR_BASE + id + '/delete.json')
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });

    }
*/
    async updateItem(item) {
        console.log("AirlineService.updateItem():");
        console.log(item);

        const body = {airline: item};
        return fetch(this.config.AIRLINE_BASE + 'edit.json', {
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .then( json => {
            return json.airline;
        })
        .catch(error => {
            this.handleError(error);
        });

    }

    handleResponseError(response) {
        throw new Error("HTTP error, status = " + response.status);
    }
    handleError(error) {
        console.log(error.message);
    }

}

export default AirlineService;
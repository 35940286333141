import React from 'react';
import AzrService from './../shared/AzrService.js';

class AzrDetails extends React.Component {

    constructor(props) {
        super(props);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCalcAzr = this.onCalcAzr.bind(this);
        this.azrService = new AzrService();
    }

    onEdit() {
        this.props.onEdit();
    }

    onCalcAzr() {
        const azr = this.props.azr;
        console.log('berechne ' + azr.id);
        this.props.onCalcAzr(azr);
    }

    onDelete() {
        const azr = this.props.azr;
        if (window.confirm("Wollen Sie den Abrechnungszeitraum wirklich löschen?")) {
            this.props.onDelete(azr.id);
        }
    }


    render() {
        const azr = this.props.azr;
        return (
            <div className="azr-details">
                <hr/>
                <h4>{azr.datum.date.substring(0, 10)}</h4>

                <table>
                    <tbody>
                        <tr>
                            <th>Azr-Nr.</th>
                            <td colSpan="2">{azr.id}</td>
                        </tr>
                        <tr>
                            <th>Rechnungen</th>
                            <td colSpan="2">{azr.anzRechnungen}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr/>
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>von</td>
                            <td>bis</td>
                        </tr>
                        <tr>
                            <th>Terminal 1</th>
                            <td>{azr.t1von}</td>
                            <td>{azr.t1bis}</td>
                        </tr>
                        <tr>
                            <th>Terminal 2</th>
                            <td>{azr.t2von}</td>
                            <td>{azr.t2bis}</td>
                        </tr>
                        <tr>
                            <th>LH Fremddeals</th>
                            <td>{azr.lhfremdvon}</td>
                            <td>{azr.lhfremdbis}</td>
                        </tr>
                        <tr>
                            <th>FAG Terminal 1</th>
                            <td>{azr.fagvon}</td>
                            <td>{azr.fagbis}</td>
                        </tr>
                        <tr>
                            <th>FAG Terminal 2</th>
                            <td>{azr.fag2von}</td>
                            <td>{azr.fag2bis}</td>
                        </tr>
                        { /*
                        <tr>
                            <th>Gesamt</th>
                            <td colSpan="2">{azr.gesamt}</td>
                        </tr>
                        <tr>
                            <th>Abschluss</th>
                            <td colSpan="2">{azr.abschluss}</td>
                        </tr>
                        <tr>
                            <th>PDFs exportiert</th>
                            <td colSpan="2">{azr.tsexportpdf}</td>
                        </tr>
                         */ }

                    </tbody>
                </table>
                <hr/>

                <div>
                    <h5>Gebührensätze in %</h5>

                    <p>{azr.mwst}% MwSt</p>
                    <p>{azr.rueckstellungLh}% Rückstellung LH</p>
                    <button onClick={() => this.onEdit()}>Bearbeiten</button>
                </div>

                <hr/>
                <h5>Dokumente</h5>
                { azr.anzRechnungen > 0 &&
                <ol style={{ marginLeft: '1.2em' }}>
                    <li><a href={this.azrService.getInvoicesDocUrl(azr.id) }>Rechnungen</a></li>

                    <li><a href={this.azrService.getStatisticsDocUrl(azr.id) }>Statistiken</a></li>

                    <li><a href={this.azrService.getOpListUrl(azr.id) }>Offene Posten</a></li>

                    <li><a href={this.azrService.getControllListUrl(azr.id) }>Kontrollstreifen</a></li>


                </ol>
                }

                <br/>
                { /*
                <button onClick={() => this.onDelete()}>Löschen</button>
                */ }
                <button onClick={() => this.onCalcAzr()}>Rechnungen erstellen</button>

            </div>
        )
    }

}

export default AzrDetails;
import React from 'react';

class FirmaField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

    }

    onKeyUp(e) {
        const key = e.key;
        //Backspace und Delete löschen gesamten Feldinhalt
        if( key === 'Backspace' || key === 'Delete') {
            e.target.value="";
            this.handleInputChange(e);
        }
    }


    handleInputChange(e) {
        this.props.handleInputChange(e);
    }

    render() {
        const fgsBilo = this.props.fgsBilo;
        return (
            <div className="form-item firma-field">
                <label className="field-name">Firma<br/>
                    <select value={fgsBilo}
                            ref={this.props.referrer}
                            name="fgsBilo"
                            required
                            onChange={this.handleInputChange}
                            onKeyDown={this.props.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            placeholder="FgsBilo">
                        <option value=""></option>
                        <option value="F">FGS</option>
                        <option value="B">Bilo</option>
                    </select>
                </label>
            </div>
        )

    }

;

}

export default FirmaField;

import React from 'react';
import FeeService from "../shared/FeeService";
import GridFormatter from '../shared/GridFormatter.js';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community"
import BooleanCheckRenderer from './BooleanCheckRenderer.js';

class GebuehrenGrid extends React.Component {

    constructor(props) {
        super(props);
        this.feeService = new FeeService();
        this.gridFormatter = new GridFormatter();
        this.onGridReady = this.onGridReady.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.createNew = this.createNew.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.navigateToNextCell = this.navigateToNextCell.bind(this);
        this.onCellEditRequest = this.onCellEditRequest.bind(this);

        this.state = {
            createNew: false,
            fees: [
                {id: 1,
                    per_order_price: 11.25,
                    // per_order_field: 'sammelNachttaxi',
                    per_piece_price: null,
                    // per_piece_field: null,
                    per_km_price: null,
                    per_km_field: null,
                    per_piece_porto: null,
                    // manual_price_field: null,
                    // calculation_formula: null,
                    art: {
                        id: 1,
                        name: '1',
                        description: 'Zone 1',
                        fieldList: 'sammelNachttaxi,nebenkosten',
                        creditor:'B',
                        aktiv: true}
                }
            ],
            columnDefs: [
                { headerName: 'Art',
                    field: 'art.description',
                    sortable: true,
                    filter: true,
                    width: 200
                },
                { headerName: 'Name',
                    field: 'art.name',
                    sortable: true,
                    filter: true,
                    sort: 'asc',
                    width: 70
                },
                { headerName: 'Preis/Auftrag',
                    field: 'per_order_price',
                    editable: true,
                    width: 70,
                },
                { headerName: 'Preis/Stück',
                    field: 'per_piece_price',
                    editable: true,
                    width: 70,
                },
                { headerName: 'Preis/Km',
                    field: 'per_km_price',
                    editable: true,
                    width: 70,
                },
                { headerName: 'Porto/Stück',
                    field: 'per_piece_porto',
                    editable: true,
                    width: 70,
                },
                { headerName: 'FGS/Bilo',
                    field: 'art.creditor',
                    filter: true,
                    width: 50
                },
                { headerName: 'aktiv',
                    field: 'art.aktiv',
                    filter: true,
                    width: 70,
                    cellRenderer: "booleanRenderer"
                }
            ],
            modules: AllCommunityModules,
            frameworkComponents:{
                booleanRenderer: BooleanCheckRenderer
            },
            getRowNodeId: function(data) {
                return data.id;
            }
            //,
            //itemToEdit: this.getEmptyFee()
        }
    }

    onGridReady = params => {
        console.log('onGridReady');
        this.gridApi = params.api;

        const updateData = fees => {
            this.setState({fees: fees});
        };

        console.log('hole Daten');
        this.feeService.getStandardFees().then(fees => {
            console.log(fees);
            updateData(fees);
        });

    };

    rowClicked() {
        // console.log('rowClicked');
        var selectedRows = this.gridApi.getSelectedRows();
        var itemToEdit = this.getEmptyFee();
        if( selectedRows.length === 1) {
            itemToEdit = selectedRows[0];

            const keys = Object.keys(itemToEdit);
            keys.forEach(key => {
                if( itemToEdit[key] === null ) {
                    itemToEdit[key] = "";
                }
            });
        }
        this.setState({itemToEdit: itemToEdit, createNew: false});
    }

    navigateToNextCell(params) {
        var suggestedNextCell = params.nextCellPosition;

        switch(params.event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                this.gridApi.forEachNode(function(node){
                   if( suggestedNextCell && suggestedNextCell.rowIndex === node.rowIndex) {
                       node.setSelected(true);
                   }
                });
                return suggestedNextCell;
            case 'ArrowLeft':
            case 'ArrowRight':
                return suggestedNextCell;
            default:
                break;
        }
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        const state = this.state;
        Object.assign(state.itemToEdit, {[name]: value});
        this.setState(state);
    }

    toggleChange(e) {
        const name = e.target.name;
        const state = this.state;

        const newState = !state.itemToEdit[name];

        Object.assign(state.itemToEdit, {[name]: newState});
        this.setState(state);
    }

    onSubmit() {
        const itemToEdit = {
            id: this.state.itemToEdit.id,
            per_order_price: this.state.itemToEdit.per_order_price,
            per_piece_price: this.state.itemToEdit.per_piece_price,
            per_km_price: this.state.itemToEdit.per_km_price,
            per_piece_porto: this.state.itemToEdit.per_piece_porto
        };

        this.feeService.updateItem(itemToEdit)
            .then(fee => {
                var rowNode = this.gridApi.getRowNode(fee.id);
                rowNode.setData(fee);
            });
    }

    onCellEditRequest(event) {
        const data = event.data;
        const field = event.colDef.field;
        const newValue = event.newValue;
        const newItem = { ...data };
        newItem[field] = event.newValue;
        console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
        /*
        rowImmutableStore = rowImmutableStore.map((oldItem) =>
            oldItem.id == newItem.id ? newItem : oldItem
        );
        setRowData(rowImmutableStore);

    },
    [rowImmutableStore]

         */
    }

    createNew() {
        const state = this.state;

        const itemToEdit = this.getEmptyFee();

        Object.assign(state, {itemToEdit: itemToEdit, createNew: true});
        this.setState(state);
        // this.refKuNr.focus();
    }


    getEmptyFee() {
        return {
            id: 1,
            per_order_price: 11.25,
            per_piece_price: null,
            per_km_price: null,
            per_km_field: null,
            per_piece_porto: null,
            art: {
                id: 1,
                name: '1',
                description: 'Zone 1',
                fieldList: 'sammelNachttaxi,nebenkosten',
                creditor:'B',
                aktiv: true}
        };
    }


    render() {

        // const itemToEdit = this.state.itemToEdit;


        return (
            <div className="fgs-tab-panel fgs-fee-panel"
                 style={{overflow:'hidden', flexGrow: 1}}>
                <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <div style={{flex: '3'}} className="ag-theme-blue">
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.fees}
                            rowSelection='single'
                            readOnlyEdit={true}
                            onCellEditRequest={this.onCellEditRequest}
                            onSelectionChanged={this.rowClicked.bind(this)}
                            frameworkComponents={this.state.frameworkComponents}
                            navigateToNextCell={this.navigateToNextCell}
                            getRowNodeId={this.state.getRowNodeId}
                            />
                    </div>


                </div>
                <div style={{flex:'0 0 60px'}}>&nbsp;</div>
            </div>
        );
    }
}

export default GebuehrenGrid;

import React from 'react';
import AzrService from '../shared/AzrService.js';

class FeeList extends React.Component {

    constructor(props) {
        super(props);
        this.azrService = new AzrService();
        this.state = {
            error: null,
            isLoaded: false,
            fees: [],
            azr: null
        };
        this.getFees = this.getFees.bind(this);
    }

    componentDidMount() {
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getFees(this.props.azr.id);
        }

    }

    componentDidUpdate(preProps) {
        if (!this.state.azr && this.props.azr) {
            this.setState({azr: this.props.azr});
        }
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getFees(this.props.azr.id);
        }
    }


    getFees(azrId) {
        this.azrService.getFees(azrId).then(fees => {
            this.setState({fees: fees, isLoaded: true});
        });
    }


    render() {
        const fees = this.state.fees;
        const listItems = fees.map((fee) =>
                <tr key={fee.id}>
                    <th>{fee.art.description}</th>
                    <th>{fee.art.name}</th>
                    <td>{fee.per_order_price}</td>
                    <td>{fee.per_piece_price}</td>
                    <td>{fee.per_km_price}</td>
                    <td>{fee.per_piece_porto}</td>
                    <td>{fee.art.creditor}</td>
                    <td>{fee.art.aktiv ? 'ja' : 'nein'}</td>
                </tr>
        );


        /*
         if (!this.state.isLoaded) {
         itemList = <div>Loading</div>
         } else {
         itemList = <table><tbody>{listAzrs}</tbody></table>
         }*/

        return (
            <div className="fgs-tab-panel fgs-fee-panel">
                <div className="fgs-fee-list">

                    <table className="fixedHead">
                        <thead>
                        <tr>
                            <th>Art</th>
                            <th>Name</th>
                            <th>Preis/Auftrag</th>
                            <th>Preis/Stück</th>
                            <th>Preis/Km</th>
                            <th>Porto/Stück</th>
                            <th>FGS/Bilo</th>
                            <th>aktiv</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listItems}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }

}

export default FeeList;
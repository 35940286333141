import React from 'react';

class SummeField extends React.Component {


    toDisplayValue(v) {
        return v.replace('.', ',');
    }


    render() {
        const endsumme = this.props.endsumme;
        const displayValue = this.toDisplayValue(endsumme);
        return (
            <div className="form-item summe-field">
                <label className="field-name">Summe<br/>
                    <input value={displayValue}
                           name="summe"
                           placeholder="Summe"
                           size="8"
                           maxLength="8"
                           ref={this.props.referrer}
                           onKeyDown={this.props.onKeyDown}
                           readOnly>
                    </input>
                </label>
            </div>
        )

    }

;

}

export default SummeField;

import React from 'react';
import AirlineService from './../../shared/AirlineService.js';


class AirlineField extends React.Component {
    constructor(props) {
        super(props);
        this.airlineService = new AirlineService();

        this.state = {
            value: "",
            airlines: [],
            isLoaded: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.getAirlines = this.getAirlines.bind(this);

    }

    componentDidMount() {
        this.getAirlines();
    }

    getAirlines() {
        this.airlineService.retrieveItems(true).then(airlines => {
            this.setState({
                airlines: airlines,
                isLoaded: true
            });
        });
    }

    handleInputChange(e, doSelect=false) {
        const airlineId = e.target.value;
        const airline = this.state.airlines.find(el => el.kundennummer === parseInt(airlineId));

        this.props.handleInputChange(e, airline);

        /*
         this.setState({
         value: target.value
         })
         */
    }

    onKeyUp(e) {
        const key = e.key;
        //Backspace und Delete löschen gesamten Feldinhalt
        if( key === 'Backspace' || key === 'Delete') {
            e.target.value="";
            this.handleInputChange(e, null, true);
        }
    }


    onSelect(e) {
        console.log('onSelect Airline');
        this.handleInputChange(e, true);
    }

    render() {
        const airline = this.props.airline;

        const airlineOptions = this.state.airlines.map((airline) =>
                <option key={airline.kundennummer}
                        value={airline.kundennummer}
                    >
                    {airline.kuerzel}
                </option>
        );

        let picker;

        if (!this.state.isLoaded) {
            picker = <div>Loading</div>
        } else {
            picker =
                <select
                    ref={this.props.referrer}
                    value={airline}
                    name="airline"
                    required
                    onChange={this.handleInputChange}
                    onSelect={this.onSelect}
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    placeholder="Airline">
                    <option value=""></option>
                    {airlineOptions}
                </select>
        }


        return (
            <div className="form-item airline-field">
                <label className="field-name">Airline<br/>
                    {picker}
                </label>
            </div>
        )

    }

;

}

export default AirlineField;

class AzrValidator {
    validateInputs(inputData) {
        let errorMsg = "";

        if(!inputData.datum) {
            errorMsg += "Bitte ein Datum eingeben.";
        }

        if(errorMsg.length === 0) {
            return true;
        } else {
            alert(errorMsg);
            return false;
        }
    }
}

export default AzrValidator;
import React from 'react';

class AzrPicker extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        console.log('handleChange ' + event.target.value);
        this.props.onSelectAzr(event.target.value);
    }


    render() {

        const azrs = this.props.azrs;
        const selectedAzr = this.props.selectedAzr;

        const listAzrs = azrs.map((azr) =>
                <option key={azr.id} value={azr.id}>
                    {azr.id} - {azr.datum.date.substring(0, 10)}
                </option>
        );

        return (
            <select
                value={selectedAzr}
                onChange={this.handleChange}>
                {listAzrs}
            </select>
        );
    }
}

export default AzrPicker;

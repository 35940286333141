// https://www.w3schools.com/react/react_forms.asp
// https://reactjs.org/docs/forms.html
// https://reactjs.org/docs/uncontrolled-components.html    => eher nicht

/*
 Konzept ähnlich wie bei ExtJS
 - Form-Komponente hat den Auftrag unter Kontrolle
 - Einzelne Formfelder sorgen selbst für ihre Validierung
 - Berechnungen etc. werden nach Validierung an das Form übergeben

 Möglichkeit besteht, den ExtJS Code in Teilen zu übernehmen
 */

import React from 'react';
import FirmaField from './FirmaField.js';
import AirlineField from './AirlineField.js';
import DatumField from './DatumField.js';
import ArtField from './ArtField.js';
import RefNrField from './RefNrField.js';
import StueckField from './StueckField.js';
import KmField from './KmField.js';
//import SammelField from './SammelField.js';
//import DirektField from './DirektField.js';
//import PostField from './PostField.js';
import NebenkostenField from './NebenkostenField.js';
import PortoField from './PortoField.js';
import AuftragPostenField from './AuftragPostenField.js';
import SummeField from './SummeField.js';

import AzrService from '../../shared/AzrService.js';
import AuftragService from '../../shared/AuftragService.js';
import AuftragValidator from '../../shared/AuftragValidator.js';

class AuftragForm extends React.Component {

    constructor(props) {
        super(props);
        this.validator = new AuftragValidator();
        this.auftragService = new AuftragService();
        this.azrService = new AzrService();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFirmaChange = this.handleFirmaChange.bind(this);
        this.handleAirlineChange = this.handleAirlineChange.bind(this);
        this.handleArtChange = this.handleArtChange.bind(this);
        this.handleStueckChange = this.handleStueckChange.bind(this);
        this.handlePortoChange = this.handlePortoChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyEvents = this.handleKeyEvents.bind(this);
        this.updateAuftragState = this.updateAuftragState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getSubmitData = this.getSubmitData.bind(this);
        this.moveFocus = this.moveFocus.bind(this);
        this.setKontrollFilter = this.setKontrollFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        // const itemToEdit = props.itemToEdit;
        this.azr = props.azr;
        // this.itemToEdit = props.itemToEdit;


        this.refSumme = React.createRef();

        // Werte müssen direkt gesetzt werden, nicht über setState
        this.state = this.createState();
    }

    createState() {

        var auftrag = {
                id: "",
                fgsBilo: "",
                airline: null,
                airlineId: "",
                auftragsdatum: "",
                art: "",
                artId: "",
                referenzNummer: "",
                stueck: "",
                km: "",
                sammelNachttaxi: "",
                direkt: "",
                post: "",
                nebenkosten: "",
                porto: "",
                endsumme: ""
        };

        return {
            azr: this.azr.id,
            fees: null,
            auftrag: auftrag,
            autoporto: true,
            itemToEdit: {}
        };
    }

    componentDidMount() {
        if (this.props.azr) {
            this.azrService.getFees(this.props.azr.id).then(fees => {
                this.setState({fees: fees});
            });
        }
    }

    /**
     * Schleuse hier einen zu bearbeitenden Auftrag ein
     * @param preProps
     */
    componentDidUpdate(preProps) {
        if( this.props.itemToEdit && this.props.itemToEdit.id !== this.state.auftrag.id ) {

            var itemToEdit = this.props.itemToEdit;
            itemToEdit.airlineId = itemToEdit.airline.kundennummer;
            itemToEdit.artId = itemToEdit.art.id;

            // erstmal null-Werte durch "" ersetzen
            const keys = Object.keys(itemToEdit);
            keys.forEach(key => {
                if( itemToEdit[key] === null ) {
                    itemToEdit[key] = "";
                }
            });

            const datum = itemToEdit.auftragsdatum;
            if( datum ) {
                const dateString = itemToEdit.auftragsdatum.date.substring(0, 10);
                itemToEdit.auftragsdatum = dateString;
/*
            } else if (datum && typeof datum === 'string' ) {
                itemToEdit.auftragsdatum = datum.substring(0, 10);
                */
            }

            var state = this.state;
            Object.assign(state.auftrag, itemToEdit);
            Object.assign(state, {autoporto: false});
            this.updateAuftragState(state);
            //this.setState(state);
        }

    }

    /**
     * Wird ausgeführt, wenn die rechnungstellende Firma (=Creditor, sprich "Fgs" oder "Bilo") geändert wurde.
     * In diesem Fall ist vor allem die Auswahl der zur Verfügung stehenden Auftragsarten
     * einzuschränken.
     */
    handleFirmaChange(e) {
        // const oldValue = this.state.auftrag.fgsBilo;
        const newValue = e.target.value;
        const state = this.state;

        Object.assign(state.auftrag, {
            fgsBilo: newValue,
            airline: "",
            airlineId: ""
        });

        Object.assign(state, {autoporto: true});

        this.updateAuftragState(state);

        // this.setState({fgsBilo: newValue, autoporto: true});
        // console.log('Firma changed from ' + oldValue + ' to ' + newValue);
        this.moveFocus(e);

    }

    /**
     * Wird ausgeführt, wenn die Airline (=Debitor) geändert wurde. In diesem Fall sind Einstellungen
     * bezüglich der zu erfassenden Felder zu aktualisieren. Zurzeit ist nur die "Referenznummer" davon
     * betroffen. Diese wird nur für eine Auswahl von Airlines erfasst.
     *
     * Die Einstellung, für welche Airline eine Ref-Nr erfasst wird, wird in den Stammdaten der Airline
     * vorgenommen ("hatReferenznummer").
     */
    handleAirlineChange(e, airline) {
        const state = this.state;
        const airlineId = airline ? airline.kundennummer : "";

        Object.assign(state.auftrag, {
                airline: airline,
                airlineId: airlineId
        });

        this.updateAuftragState(state);
    }

    handleArtChange(art) {
        const state = this.state;
        Object.assign(state.auftrag, {
            art: art,
            artId: art.id
        });

        this.updateAuftragState(state);
        // this.moveFocus({target:{name:'art'}});
    }

    handleStueckChange(e) {
        const stueck = e.target.value;
        const name = e.target.name;
        const state = this.state;


        const newValue = {[name]: stueck};
        Object.assign(state.auftrag, newValue);

        this.updateAuftragState(state);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const state = this.state;
        Object.assign(state.auftrag, {[name]: value});

        this.updateAuftragState(state);
        // console.log('changed ' + name + ' to ' + value);

        if( name === 'referenzNummer' && value.length === 10 ) {
            this.moveFocus(event);
        }
    }

    handlePortoChange(event, autoporto = false) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        // console.log('handlePortoChange, value="' + value + '", autoporto= '+autoporto);

        const state = this.state;

        // const autoporto = value === "" ? true : false;
        // console.log('autoporto ' + autoporto);

        Object.assign(state.auftrag, {[name]: value});
        Object.assign(state, {autoporto: autoporto});
        // console.log(state);

        this.updateAuftragState(state);
        // console.log('changed ' + name + ' to ' + value);
    }

    handleBlur(e) {
        // this.doCalculation();
    }

    updateAuftragState(state) {
        // console.log('updateAuftragState');
        // console.log(state);
        // console.log('Call onEditChange');
        this.props.onUpdateFilterAttributes(state.auftrag.fgsBilo, state.auftrag.airline);

        // Wenn keine Auftragsart gesetzt ist => abbrechen
        if (!state.auftrag.art) {
            // Object.assign(state, {sammelNachttaxi: 0, direkt: 0, post: 0, nebenkosten: 0, porto: 0});
            this.setState(state);
            return;
        }

        if (!state.fees) {
            console.warn('Gebühren nicht geladen');
            // Object.assign(state, {sammelNachttaxi: 0, direkt: 0, post: 0, nebenkosten: 0, porto: 0});
            this.setState(state);
            return;
        }

        // Wenn keine Gebühren geladen sind => abbrechen
        const fee = state.fees.find(el => el.art.id === state.auftrag.art.id);
        if (!fee) {
            console.warn('keine gebuehr');
            Object.assign(state.auftrag, {sammelNachttaxi: 0, direkt: 0, post: 0, porto: 0});
            this.setState(state);
            return;
        }

        this.resetCalculatedFields(state, fee);
        this.calcPerOrderPrice(fee, state);
        this.calcPerPiecePrice(fee, state);
        this.calcPerKmPrice(fee, state);
        this.calcPerPiecePorto(fee, state);


        const sammelNachttaxi = parseFloat(state.auftrag.sammelNachttaxi);
        const direkt = parseFloat(state.auftrag.direkt);
        const post = parseFloat(state.auftrag.post);
        const nebenkosten = parseFloat(state.auftrag.nebenkosten);
        const porto = parseFloat(state.auftrag.porto);

        var endsumme = (isNaN(sammelNachttaxi) ? 0 : sammelNachttaxi) +
            (isNaN(direkt) ? 0 : direkt) +
            (isNaN(post) ? 0 : post) +
            (isNaN(nebenkosten) ? 0 : nebenkosten) +
            (isNaN(porto) ? 0 : porto);

        endsumme = Math.round(endsumme * 100) / 100;
        Object.assign(state.auftrag, {endsumme: endsumme});

        // console.log(state);
        // this.setState(state);
    }

    /**
     * Berechne die Gebühr für den gesamten Auftrag und trage sie
     * in den entsprechenden Rechnungsposten ein
     *
     * @param fee
     * @param state
     */
    calcPerOrderPrice(fee, state) {
        if (fee.per_order_price) {
            Object.assign(state.auftrag, {[fee.per_order_field]: fee.per_order_price});
        }
    }

    /**
     * Berechne die kilometerabhängigen Gebühren und trage sie
     * in den entsprechenden Rechnungsposten ein
     *
     * @param fee
     * @param state
     */
    calcPerKmPrice(fee, state) {
        if (fee.per_km_price) {
            var perKm = Math.round(fee.per_km_price * state.auftrag.km * 100) / 100;

            // Es kann vorkommen, dass der Pro-Auftrag-Preis
            // im selben Feld steht wie der Pro-Stück-Preis
            // In diesem Fall wird das entsprechende Betrag
            // hier addiert.
            if (fee.per_order_field === fee.per_km_field) {
                perKm += parseFloat(fee.per_order_price);
            }

            Object.assign(state.auftrag, {[fee.per_km_field]: perKm})
        }
    }


    /**
     * Berechne die stückzahlabhängigen Gebühren und trage sie
     * in den entsprechenden Rechnungsposten ein
     *
     * @param fee
     * @param state
     */
    calcPerPiecePrice(fee, state) {
        if (fee.per_piece_price) {
            var perPiece = Math.round(fee.per_piece_price * state.auftrag.stueck * 100) / 100;

            // Es kann vorkommen, dass der Pro-Auftrag-Preis
            // im selben Feld steht wie der Pro-Stück-Preis
            // In diesem Fall wird das entsprechende Betrag
            // hier addiert.
            if (fee.per_order_field === fee.per_piece_field) {
                perPiece += parseFloat(fee.per_order_price);
            }

            Object.assign(state.auftrag, {[fee.per_piece_field]: perPiece})
        }
    }

    /**
     * Berechne die Porto-Gebühren und trage sie
     * in das Portofeld ein
     *
     * @param fee
     * @param state
     */
    calcPerPiecePorto(fee, state) {
        // console.log('calcPortoValue');
        // console.log(state);
        // Porto wird leergestellt falls
        // "Porto" nicht in der Fieldlist ist
        var porto = state.auftrag.porto;

        if( !fee.per_piece_porto && fee.art.fieldList.indexOf('porto') < 0 ) {
            porto = "";
        }

        if( fee.per_piece_porto && state.autoporto ) {
        // Porto wird neu berechnet, wenn Porto
        // in Fieldlist ist und autoporto true ist
            porto = Math.round((state.auftrag.stueck * fee.per_piece_porto * 100) / 100);
        }

        Object.assign(state.auftrag, {porto: porto});
        // return state.porto;
    }



    /**
     * Setzt die Werte der berechneten Felder auf "", sofern das für den
     * aktuellen Auftragstyp erfordelrich ist.
     *
     * @param state
     * @param fee
     * @returns {*}
     */
    resetCalculatedFields(state, fee) {

        // Haben wir berechnetes Porto?
        // state.autoporto = fee.per_piece_porto !== "" ? true : false;

        if ( !fee.art.fieldList || fee.art.fieldList.indexOf('km') < 0) {
            state.auftrag.km = "";
        }
        if (fee.manual_price_field !== 'sammelNachttaxi') {
            state.auftrag.sammelNachttaxi = "";
        }

        if (fee.manual_price_field !== 'direkt') {
            state.auftrag.direkt = "";
        }
        /*
        if (fee.manual_price_field !== 'nebenkosten') {
            state.nebenkosten = 0;
        }
        */

        if (fee.manual_price_field !== 'post') {
            state.auftrag.post = "";
        }

        if (fee.manual_price_field !== 'porto' && state.autoporto) {
            state.auftrag.porto = "";
        }

        // Object.assign(state,{});
        // return state;
    }



    /**
     * handleKeyEvents
     *
     * Behandlung der Tastaturevents zur besseren Navigation
     * im Formular.
     *
     * @param e
     */
    handleKeyEvents(e) {
        const key = e.key;
        const target = e.target;
        // console.log('Pressed '  + key + ' in Feld ' + target.name );

        // console.log( 'CtrlKey: ' + e.ctrlKey);

        switch (key) {
            case 'k':				// Strg + K
                if (e.ctrlKey) {
                    e.preventDefault();
                    this.props.onToggleFilter();
                }
                break;
            case '*':
            case 'ArrowLeft':
                e.preventDefault();
                // console.log('ArrowLeft');
                this.moveFocus(e, 'previous');
                break;
            case 'Tab':
            case 'ArrowRight':
                e.preventDefault();
                this.moveFocus(e, 'next');
                break;
            case 'Enter':
                e.preventDefault();
                if( target.name === 'submit' )
                {
                    this.onSubmit();
                    break;
                }
                this.moveFocus(e, 'next');
                break;
            case '-':
                e.preventDefault();
                this.clearForm();
                this.refDatum.focus();
                break;
            case '/':
                e.preventDefault();
                if (target.name === 'porto') {
                    this.setState({autoporto: true});
                }
                // console.log('dup from auftrag ');
                const allAuftraege = this.props.context.componentParent.gridApi.rowModel.rootNode.childrenAfterSort;
                const lastIndex = allAuftraege.length - 1;
                if( lastIndex < 0 ) {
                    console.warn('Es gibt keinen zuletzt erfassten Auftrag');
                    return;
                }
                const prevAuftrag = allAuftraege[lastIndex].data;

                var prevValue = prevAuftrag[target.name] ? prevAuftrag[target.name] : "";
                const state = this.state;
                switch (target.name) {
                    case 'art':
                        if( prevValue && prevValue.id ) {
                            Object.assign(state.auftrag, {art: prevValue, artId: prevValue.id});
                        }
                        break;
                    case 'airline':
                        if( prevValue && prevValue.kundennummer ) {
                            Object.assign(state.auftrag, {airline: prevValue, airlineId: prevValue.kundennummer});
                        }
                        break;
                    case 'referenzNummer':
                        if( prevValue ) {
                            Object.assign(state.auftrag, {referenzNummer: prevValue.substr(0,5)});
                        }
                        break;
                    case 'endsumme':
                        this.moveFocus(e);
                        return;
                    default:
                        Object.assign(state.auftrag, {[target.name]: prevValue});
                        break;
                }
                // console.log(prevValue);
                this.updateAuftragState(state);
                if( target.name !== 'referenzNummer' ) {
                    this.moveFocus(e);
                }
                break;

            /*
            case e.TAB: 			// Tab
            case e.ENTER:			// Enter  				Fokus auf das nächste zu bearbeitende Feld
                                     // Weiche für Felder, bei denen die "normale" Keyboard-Navigation mit Enter- und Tab-Taste funktionieren soll
                if (field.allowEnterKeyNavigation == true) {
                    e.preventDefault();
                    me.focusNext(field, true);
                }
                break;
            case e.UP: 				// Pfeiltaste oben		Fokus auf Auftrag-Grid
                me.fireEvent('arrowuppressed');
                break;
            case e.LEFT:			// Pfeiltaste links
                if (field.inputEl) {
                    var selStart = field.inputEl.selectionStart;
                    var selEnd = field.inputEl.selectionEnd;
                    if (selEnd - selStart == 0) {
                        return;
                    }
                }
            case e.NUM_PLUS: 		// Numpad "+"			Ermöglicht das Ändern des aktuellen Feldes: Setzt den Cursor auf den Anfang des Feldes.
                e.preventDefault();							// Im Porto-Feld wird in den manuellen Modus geschaltet
                if (target.name == 'porto') {
                    me.setAutoPorto(false);
                }
                field.selectText();
                break;
            case e.NUM_DIVISION: 	// Numpad "/"			Erstellt eine Kopie des zuvor erfassten Auftrags
                e.preventDefault();							// Im Porto-Feld wird in den Automatik-Modus geschaltet
                if (target.name == 'porto') {
                    me.setAutoPorto(true);
                }
                me.fireEvent('copyfrompreviousjob', field);
                break;
            case e.F5:				// F5					Lädt die Seite neu. ??? Unterdrücken ???
                break;
            */
            default:										// Ansonsten nur valide Keys zulassen
                // Sofern das Portofeld auf Autoporto steht und eine Ziffer eingetragen wird, schalten
                // wir in den manuellen Eingabemodus um
                // console.log('Default...');
                if (target.name === 'porto' && this.state.autoporto && (
                        ( key >= e.NUM_ZERO && key <= e.NUM_NINE ) ||
                        ( key >= e.ZERO && key <= e.NINE )
                    )) {
                    this.setState({autoporto: false});
                }
                break;
        }

    }


    getSubmitData() {
        const auftrag = {
            azr: this.props.azr.id,
            fgsBilo: this.state.auftrag.fgsBilo,
            airline: this.state.auftrag.airlineId,
            auftragsdatum: this.state.auftrag.auftragsdatum,
            art: this.state.auftrag.artId,
            referenzNummer: this.state.auftrag.referenzNummer,
            stueck: this.state.auftrag.stueck,
            km: this.state.auftrag.km,
            sammelNachttaxi: this.state.auftrag.sammelNachttaxi,
            direkt: this.state.auftrag.direkt,
            post: this.state.auftrag.post,
            nebenkosten: this.state.auftrag.nebenkosten,
            porto: this.state.auftrag.porto,
            endsumme: this.state.auftrag.endsumme,
            id: this.state.auftrag.id
        };
        return auftrag;
    }

    onSubmit() {
        const auftrag = this.getSubmitData();
        if (this.validator.validateInputs(auftrag)) {
            this.clearForm();

            if( !auftrag.id ) {
                this.props.onCreate(auftrag);
            } else {
                this.props.onUpdate(auftrag);
            }
            this.refDatum.focus();
        }
    }

    clearForm() {
        var state = this.state;
        Object.assign(state.auftrag, {
            auftragsdatum: "",
            // art: "",
            // artId: "",
            referenzNummer: "",
            stueck: "",
            km: "",
            sammelNachttaxi: "",
            direkt: "",
            post: "",
            nebenkosten: "",
            porto: "",
            endsumme: "",
            autoporto: true,
            id: ""
        });
        this.setState(state);
        // nach oben geben, sonst kommt ggf. der zu bearbeitende
        // Auftrag wieder nach unten
        this.props.stopEditing();
    }

    moveFocus(e, direction = 'next'){
        const fieldName = e.target.name;
        // console.log('aktuell im Fokus: ' + fieldName);
        // console.log('Richtung: ' + direction);

        const fields=[
            {name: 'fgsBilo', ref: this.refFirma},
            {name: 'airline', ref: this.refAirline},
            {name: 'auftragsdatum', ref: this.refDatum},
            {name: 'art', ref: this.refArt},
            {name: 'referenzNummer', ref: this.refRefnr},
            {name: 'stueck', ref: this.refStueck},
            {name: 'km', ref: this.refKm},
            {name: 'sammelNachttaxi', ref: this.refSammelNachttaxi},
            {name: 'direkt', ref: this.refDirekt},
            {name: 'post', ref: this.refPost},
            {name: 'nebenkosten', ref: this.refNebenkosten},
            {name: 'porto', ref: this.refPorto},
            {name: 'summe', ref: this.refSumme},
            {name: 'submit', ref: this.refSubmit}
        ];
        const currentField = fields.find(f => f.name === fieldName);
        const fieldIndex = fields.indexOf(currentField);

        var field;
        var i;
        if( direction === 'next' ) {
            for(i = fieldIndex + 1; i <= fields.length - 1; i++) {
                field = fields[i].ref;
                // console.log(field.name);
                // console.log('disabled? ' + field.disabled);
                // console.log('readonly? ' + field.readOnly);
                if( !field.disabled ) {
                    field.focus();
                    break;
                }
            }
        }

        if( direction === 'previous') {
            for(i = fieldIndex -1; i >= 0; i--) {
                field = fields[i].ref;
                // console.log(field.name);
                // console.log('disabled? ' + field.disabled);
                // console.log('readonly? ' + field.readOnly);
                if( !field.disabled ) {
                    field.focus();
                    break;
                }
            }

        }
    }

    setKontrollFilter() {
        // console.log('setKontrollFilter');
        // this.props.onEditChange(this.state.fgsBilo, this.state.airline);
        this.props.onToggleFilter();
    }

    clearFilter() {
        this.props.clearFilter();
    }

    render() {
        const id = this.state.auftrag.id;
        const azr = this.props.azr;
        const fees = this.state.fees;
        const fgsBilo = this.state.auftrag.fgsBilo;
        const airline = this.state.auftrag.airline;
        const airlineId = this.state.auftrag.airlineId;
        const auftragsdatum = this.state.auftrag.auftragsdatum;
        const art = this.state.auftrag.art;
        const artId = this.state.auftrag.artId;
        const referenzNummer = this.state.auftrag.referenzNummer;
        const stueck = this.state.auftrag.stueck;
        const km = this.state.auftrag.km;
        const sammelNachttaxi = this.state.auftrag.sammelNachttaxi;
        const direkt = this.state.auftrag.direkt;
        const post = this.state.auftrag.post;
        const nebenkosten = this.state.auftrag.nebenkosten;
        const porto = this.state.auftrag.porto;
        const autoporto = this.state.autoporto;
        const endsumme = this.state.auftrag.endsumme;
        const mode = id ? 'edit' : 'new';
        const formClassName = "fgs-auftrag-form input-panel form-" + mode;
        return (
            <div className={formClassName}>
                <div className="form-title">
                    <span>Aufträge erfassen / bearbeiten</span>&nbsp;&nbsp;
                    <button
                        disabled={!airline || !fgsBilo }
                        onClick={this.setKontrollFilter}>Strg + K
                    </button>
                    <button
                        onClick={this.clearFilter}>Alle anzeigen
                    </button>
                    <button
                        onClick={this.clearForm}>Leer stellen
                    </button>
                    <span className="edit-auftrag-id">{id}</span>
                </div>

                <div className="form-body">

                    <FirmaField
                        referrer={ref => this.refFirma = ref}
                        fgsBilo={fgsBilo}
                        handleInputChange={this.handleFirmaChange}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <AirlineField
                        referrer={ref => this.refAirline = ref}
                        airline={airlineId}
                        handleInputChange={this.handleAirlineChange}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <DatumField
                        referrer={ref => this.refDatum = ref}
                        auftragsdatum={auftragsdatum}
                        onKeyDown={this.handleKeyEvents}
                        handleInputChange={this.handleInputChange}
                        azr={azr}
                        />

                    <ArtField
                        referrer={ref => this.refArt = ref}
                        art={artId}
                        firma={fgsBilo}
                        handleInputChange={this.handleArtChange}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <RefNrField
                        referrer={ref => this.refRefnr = ref}
                        referenzNummer={referenzNummer}
                        handleInputChange={this.handleInputChange}
                        airline={airline}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <StueckField
                        referrer={ref => this.refStueck = ref}
                        stueck={stueck}
                        handleInputChange={this.handleInputChange}
                        handleBlur={this.handleBlur}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <KmField
                        referrer={ref => this.refKm = ref}
                        km={km}
                        handleInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyEvents}
                        art={art}/>

                    <AuftragPostenField
                        referrer={ref => this.refSammelNachttaxi = ref}
                        name="sammelNachttaxi"
                        value={sammelNachttaxi}
                        label="Sammel"
                        stueck={stueck}
                        art={art}
                        fees={fees}
                        handleInputChange={this.handleInputChange}
                        handleBlur={this.handleBlur}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <AuftragPostenField
                        referrer={ref => this.refDirekt = ref}
                        name="direkt"
                        value={direkt}
                        label="Direkt"
                        stueck={stueck}
                        art={art}
                        fees={fees}
                        handleInputChange={this.handleInputChange}
                        handleBlur={this.handleBlur}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <AuftragPostenField
                        referrer={ref => this.refPost = ref}
                        name="post"
                        value={post}
                        label="Post"
                        stueck={stueck}
                        art={art}
                        fees={fees}
                        handleInputChange={this.handleInputChange}
                        handleBlur={this.handleBlur}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <NebenkostenField
                        referrer={ref => this.refNebenkosten = ref}
                        value={nebenkosten}
                        handleInputChange={this.handleInputChange}
                        handleBlur={this.handleBlur}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <PortoField
                        referrer={ref => this.refPorto = ref}
                        value={porto}
                        handleInputChange={this.handlePortoChange}
                        art={art}
                        fees={fees}
                        autoporto={autoporto}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <SummeField
                        referrer={ref => this.refSumme = ref}
                        endsumme={String(endsumme)}
                        onKeyDown={this.handleKeyEvents}
                        />

                    <button
                        name='submit'
                        ref={ref => this.refSubmit = ref}
                        onClick={() => this.onSubmit()}
                        onKeyDown={this.handleKeyEvents}
                        >Speichern
                    </button>

                </div>

                <br/>

            </div>
        );
    }

}

export default AuftragForm;



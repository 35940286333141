import React from 'react';
import AuftragService from './../../shared/AuftragService.js';


class ArtField extends React.Component {
    constructor(props) {
        super(props);
        this.auftragService = new AuftragService();

        this.state = {
            value: "",
            arten: [],
            isLoaded: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAuftragsarten = this.getAuftragsarten.bind(this);
        this.getOption = this.getOption.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

    }

    componentDidMount() {
        this.getAuftragsarten();
    }

    getAuftragsarten() {
        this.auftragService.retrieveAuftragsarten().then(arten => {
            this.setState({
                arten: arten,
                isLoaded: true
            });
        });
    }

    onKeyUp(e) {
        const key = e.key;
        //Backspace und Delete löschen gesamten Feldinhalt
        if( key === 'Backspace' || key === 'Delete') {
            e.target.value="";
            this.handleInputChange(e, null);
        }
    }


    handleInputChange(e) {
        const artId = e.target.value;
        const art = artId ? this.state.arten.find(el => el.id === parseInt(artId)) : "";

        this.props.handleInputChange(art);
    }

    getOption(art, firma) {
        if (art.creditor.indexOf(firma) >= 0 && art.aktiv ) {
            return (
                <option key={art.id} value={art.id}>
                    {art.name}
                </option>);
        }
    }

    render() {
        const art = this.props.art;
        const firma = this.props.firma;
        // const re = new RegExp(".*" + firma + ".*");

        const artOptions = this.state.arten.map((art) =>
                this.getOption(art, firma)
        );

        let picker;

        if (!this.state.isLoaded) {
            picker = <div>Loading</div>
        } else {
            picker =
                <select
                    ref={this.props.referrer}
                    value={art}
                    name="art"
                    required
                    onChange={this.handleInputChange}
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.onKeyUp}
                    placeholder="Art">
                    <option value=""></option>
                    {artOptions}
                </select>
        }


        return (
            <div className="form-item art-field">
                <label className="field-name">Art<br/>
                    {picker}
                </label>
            </div>
        )

    }

;

}

export default ArtField;

// https://dzone.com/articles/consuming-rest-api-with-reactjs

// todo: CORS Preflight-Listener in Symfony einbauen
// https://www.upbeatproductions.com/blog/cors-pre-flight-requests-and-headers-symfony-httpkernel-component

// import Configuration from './../Configuration.js';
import BaseService from "./BaseService";

class AzrService extends BaseService {

    // eslint-disable-next-line
    constructor() {
        super();
        // this.config = new Configuration();
    }

    async retrieveItems() {
        return fetch( this.config.AZR_BASE + 'list.json')
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.azrs;
            })
            .catch(error => {
                this.handleError(error)
            })
    }

    async getAzr(id) {
        return fetch(this.config.AZR_BASE + 'show/' + id + '.json')
            .then( response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then( json => {
                return json.azr;
            })
            .catch(error => {
                this.handleError(error);
            }
        );
    }

    async getFees(id) {
        return fetch(this.config.FEE_BASE + 'list.json?azrId=' + id)
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.fees;
            })
            .catch(error => {
                this.handleError(error);
            }
        );

    }

    async createAzr(azr) {
        const body = {"azr": azr};
        return fetch(this.config.AZR_BASE + 'create.json', {
            method: 'put',
            body: JSON.stringify(body)
        })
        .then(response => {
            if(!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .then( json => {
            return json.azr;
        })
        .catch(error => {
            this.handleError(error);
        });

    }

    async deleteAzr(id) {
        return fetch(this.config.AZR_BASE + id + '/delete.json')
            .then(response => {
                if(!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });

    }

    async updateAzr(azr) {
        const body = {"azr": {
            "id": azr.id,
            "mwst": azr.mwst,
            "rueckstellungLh": azr.rueckstellungLh}
        };

        return fetch(this.config.AZR_BASE + 'edit.json', {
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            }
            return response.json();
        })
        .catch(error => {
            this.handleError(error);
        });

    }

    async calcInvoicesAndStats(azrId) {
        return fetch(this.config.AZR_BASE + 'generateInvoicesAndStats.json?azrId=' + azrId, {
            method: "GET"
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                return json.azr;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getInvoicesDocUrl(azrId) {
        return this.config.AZR_BASE + azrId + '/getInvoicesDocument.pdf';
    }

    getStatisticsDocUrl(azrId) {
        return this.config.AZR_BASE + azrId + '/getStatisticsDocument.pdf';
    }

    getOpListUrl(azrId) {
        return this.config.AZR_BASE + azrId + '/getOpList.pdf';
    }

    getControllListUrl(azrId) {
        return this.config.AZR_BASE + azrId + '/getControllList.pdf';
    }

/*
    handleResponseError(response) {
        console.log('handleResponseError - response.status = ' + response.status);
        if( response.status === 401 ) {
            console.log('Führe das Logout durch.');
            const userService = new UserService();
            userService.logoutUser();
            window.location.reload();
            return
        }
        throw new Error("HTTP error, status = " + response.status);
    }

 */
    handleError(error) {
        console.log(error.message);
    }

}

export default AzrService;
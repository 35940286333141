import React from 'react';
import StatisticService from '../shared/StatisticService.js';


class StatisticList extends React.Component {

    constructor(props) {
        super(props);
        this.statisticService = new StatisticService();
        this.state = {
            error: null,
            isLoaded: false,
            statistics: [],
            azr: null
        };
        this.getStats = this.getStats.bind(this);
        // this.onSelectAzr = this.onSelectAzr.bind(this);
        // this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getStats(this.props.azr.id);
        }
    }

    componentDidUpdate() {
        if (!this.state.azr && this.props.azr) {
            this.setState({azr: this.props.azr});
        }
        if (this.state.azr !== this.props.azr) {
            this.setState({azr: this.props.azr});
            this.getStats(this.props.azr.id);
        }
    }


    getStats(azr) {
        this.statisticService.retrieveItems(azr).then(items => {
            this.setState({statistics: items, isLoaded: true});
        });
    }

    handleDblClick(itemId) {
        window.open(this.statisticService.getUrl2Pdf(itemId));
    }


    render() {
        const statistics = this.state.statistics;
        const listItems = statistics.map((stat) =>
                <tr key={stat.id} onDoubleClick={() => this.handleDblClick(stat.id)}>
                    <th>{stat.lfdNr}</th>
                    <td>{stat.description}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(stat.fgsNetto)}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(stat.fgsMwst)}</td>
                    <td className="betrag">{new Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(stat.gesamt)}</td>
                    <td>...</td>
                </tr>
        );


        /*
         if (!this.state.isLoaded) {
         itemList = <div>Loading</div>
         } else {
         itemList = <table><tbody>{listAzrs}</tbody></table>
         }*/

        return (
            <div className="fgs-tab-panel fgs-statistics-panel">
                <div className="fgs-statistics-list">

                    <table className="fixedHead">
                        <thead>
                        <tr>
                            <th>SNr</th>
                            <th>Statistik</th>
                            <th>FGS Netto</th>
                            <th>MwSt</th>
                            <th>Gesamt</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listItems}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }

}

export default StatisticList;
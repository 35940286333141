import React from 'react';

class DatumField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.toValue = this.toValue.bind(this);

        this.state = {
            azr: ""
        };
    }

    toValue(v){
        if( v === "" ) {
            return "";
        }
        const re = new RegExp(/^(\d{1,2}(\.\d{0,2})?(\.\d{0,4})?)$/);
        if( !v.match(re) ) {
            // console.log('invalid: ' + v);
            return false;
        }

        const azrDatum = new Date(this.props.azr.datum.date);
        const azrDay = azrDatum.getDate();
        const azrMonth = azrDatum.getMonth() + 1;
        const azrYear = azrDatum.getFullYear();

        const datum = v.split('.');
        var day = parseInt(datum[0]);
        var month = datum.length > 1 ? parseInt(datum[1]) : null;
        var year = datum.length > 2 ? parseInt(datum[2]) : null;

        // wenn im Eingabefeld kein Monat gesetzt ist,
        // dann wird der Monat des AZR genommen
        if( !month ) {
            month = azrMonth;
            // Außer der angegebene Tag ist größer als der
            // Tag des AZR (z.B. 16 zur Monatsmitte), dann
            // wird angenommen, dass es sich um einen Tag im Vormat
            // handelt
            if (day > azrDay) {
                month = month > 1 ? month-1 : 12;
            }
        }

        if( !year ) {
            year = azrYear;
            if( day > azrDay && month === 12 ) {
                year = year-1;
            }
        }

        if( year < 2000) {
            year = year + 2000;
        }

        day = day >= 10 ? day.toString() : '0' + day.toString();
        month = month >= 10 ? month.toString() : '0' + month.toString();

        const dateString = [year,month,day].join('-');

        const newDate = new Date(dateString);
        // console.log(newDate);

        if( !(newDate instanceof Date) || isNaN(newDate)) {
            console.log('invalid');
            return false;
        }
        // console.log(newDate.toISOString().substr(0,10));
        // console.log(dateString);
        return newDate.toISOString().substr(0,10);
    }

    onBlur(e){
        const date = e.target.value;
        const newDate = this.toValue(date);
        if( newDate === false ) {
            return;
        }
        e.target.value = newDate;
        this.props.handleInputChange(e);
    }

    /**
     * Prüfe ob der eingegebene Wert dem Muster eines Datums
     * entspricht. Wenn ja, alles gut, propagiere INPUT-Change
     * nach oben mit dem aktuellen Wert.
     * Wenn nein - propagiere keinen Input-Change - der Feldwert
     * nimmt die neue Eingabe nicht an, es bleibt beim alten Wert.
     *
     * @param e
     */
    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        const date = e.target.value;
        const newDate = this.toValue(date);
        if( newDate === false ) {
            return;
        }

        this.props.handleInputChange(e);
    }

    toDisplayValue(v) {
        if( v && v.toString().match(/^\d{4}-\d{2}-\d{2}$/) ) {
            const datum = v.split('-');
            return datum[2] + '.' + datum[1] + '.' + datum[0];
        }
        if( !v ) {
            return "";
        }
        return v;
    }

    render() {
        const auftragsdatum = this.props.auftragsdatum;
        const displayValue = this.toDisplayValue(auftragsdatum);
        return (
            <div className="form-item datum-field">
                <label className="field-name">Datum<br/>
                    <input value={displayValue}
                           ref={this.props.referrer}
                           name="auftragsdatum"
                           maxLength="10"
                           size="10"
                           onKeyDown={this.props.onKeyDown}
                           onChange={this.handleInputChange}
                           onBlur={this.onBlur}
                           placeholder="Datum">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default DatumField;

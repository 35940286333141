import React from 'react';
import AirlineService from '../shared/AirlineService.js';
import AirlineValidator from '../shared/AirlineValidator.js';
import GridFormatter from '../shared/GridFormatter.js';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community"
import BooleanCheckRenderer from './BooleanCheckRenderer.js';

class AirlinesGrid extends React.Component {

    constructor(props) {
        super(props);
        this.airlineService = new AirlineService();
        this.airlineValidator = new AirlineValidator();
        this.gridFormatter = new GridFormatter();
        this.onGridReady = this.onGridReady.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.createNew = this.createNew.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.navigateToNextCell = this.navigateToNextCell.bind(this);

        this.state = {
            createNew: false,
            airlines: [
                {kuerzel: 'LH', kundennummer: '12345', name: 'Lufthansa', aktiv: true}
            ],
            columnDefs: [
                { headerName: 'Kürzel',
                    field: 'kuerzel',
                    sortable: true,
                    filter: true,
                    width: 50
                },
                { headerName: 'KuNr',
                    field: 'kundennummer',
                    sortable: true,
                    filter: true,
                    sort: 'asc',
                    width: 70
                },
                { headerName: 'Airline',
                    field: 'name',
                    sortable: true,
                    filter: true,
                    minWidth: 200,
                    flex: 1
                },
                { headerName: 'aktiv',
                    field: 'aktiv',
                    filter: true,
                    width: 50,
                    cellRenderer: "booleanRenderer"
                },
                { headerName: 'Kontrollstreifen',
                    field: 'kontrollListe',
                    filter: true,
                    width: 50,
                    cellRenderer: "booleanRenderer"
                },
                { headerName: 'RefNr',
                    field: 'hatReferenznummer',
                    filter: true,
                    width: 50,
                    cellRenderer: "booleanRenderer"
                },
                { headerName: 'Fremdabrechnung',
                    field: 'fremdKuerzel',
                    filter: true,
                    width: 50
                },
                { headerName: 'Limit',
                    field: 'rechnungslimit',
                    filter: true,
                    width: 80,
                    valueFormatter: this.gridFormatter.currencyFormatter
                }
            ],
            modules: AllCommunityModules,
            frameworkComponents:{
                booleanRenderer: BooleanCheckRenderer
            },
            getRowNodeId: function(data) {
                return data.kundennummer;
            },
            itemToEdit: this.getEmptyAirline()
        }
    }

    onGridReady = params => {
        console.log('onGridReady');
        this.gridApi = params.api;

        const updateData = airlines => {
            this.setState({airlines: airlines});
        };

        console.log('hole Daten');
        this.airlineService.retrieveItems(false).then(airlines => {
            // console.log(airlines);
            updateData(airlines);
        });

    };

    rowClicked() {
        // console.log('rowClicked');
        var selectedRows = this.gridApi.getSelectedRows();
        var itemToEdit = this.getEmptyAirline();
        if( selectedRows.length === 1) {
            itemToEdit = selectedRows[0];

            const keys = Object.keys(itemToEdit);
            keys.forEach(key => {
                if( itemToEdit[key] === null ) {
                    itemToEdit[key] = "";
                }
            });
        }
        this.setState({itemToEdit: itemToEdit, createNew: false});
    }

    navigateToNextCell(params) {
        var suggestedNextCell = params.nextCellPosition;

        switch(params.event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                this.gridApi.forEachNode(function(node){
                   if( suggestedNextCell && suggestedNextCell.rowIndex === node.rowIndex) {
                       node.setSelected(true);
                   }
                });
                return suggestedNextCell;
            case 'ArrowLeft':
            case 'ArrowRight':
                return suggestedNextCell;
            default:
                break;
        }
    }

    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        const state = this.state;


        if( name === 'kundennummer') {
            if( state.airlines.findIndex(el => el.kundennummer === parseInt(value)) >= 0 ) {
                alert('Es gibt bereits eine Airline mit der Kundennummer ' + value);
                return;
            }
        }

        /*
        if( name === 'kuerzel') {
            if( state.airlines.findIndex(el => el.kuerzel === value) >= 0 ) {
                alert('Es gibt bereits eine Airline mit dem Kürzel ' + value);
                return;
            }
        }
        */

        Object.assign(state.itemToEdit, {[name]: value});
        this.setState(state);
    }

    toggleChange(e) {
        const name = e.target.name;
        const state = this.state;

        const newState = !state.itemToEdit[name];

        Object.assign(state.itemToEdit, {[name]: newState});
        this.setState(state);
    }

    onSubmit() {
        const itemToEdit = {
            kundennummer: this.state.itemToEdit.kundennummer,
            kuerzel: this.state.itemToEdit.kuerzel,
            ustId: this.state.itemToEdit.ustId,
            stnr: this.state.itemToEdit.stnr,
            name: this.state.itemToEdit.name,
            adresse: this.state.itemToEdit.adresse,
            kontakt: this.state.itemToEdit.kontakt,
            strassePostfach: this.state.itemToEdit.strassePostfach,
            plz: this.state.itemToEdit.plz,
            ort: this.state.itemToEdit.ort,
            kontrollListe: this.state.itemToEdit.kontrollListe,
            rechnungslimit: this.state.itemToEdit.rechnungslimit,
            aktiv: this.state.itemToEdit.aktiv,
            hatReferenznummer: this.state.itemToEdit.hatReferenznummer,
            staat: this.state.itemToEdit.staat,
            fremdabrechnungMit: this.state.itemToEdit.fremdabrechnungMit
        };


        if( this.state.createNew ) {
            if( this.airlineValidator.validateInputs(itemToEdit, this.state.airlines) ) {
                this.airlineService.createItem(itemToEdit)
                    .then(airline => {
                        if (airline.fremdabrechnungMit) {
                            Object.assign(airline, {
                                fremdabrechnungMit: airline.fremdabrechnungMit.kundennummer,
                                fremdKuerzel: airline.fremdabrechnungMit.kuerzel
                            });
                        }
                        this.gridApi.updateRowData(
                            {
                                add: [airline]
                            }
                        );
                    });
                this.setState({createNew: false});
            }
        } else {
            this.airlineService.updateItem(itemToEdit)
                .then(airline => {
                    if( airline.fremdabrechnungMit ) {
                        Object.assign(airline, {
                            fremdabrechnungMit: airline.fremdabrechnungMit.kundennummer,
                            fremdKuerzel: airline.fremdabrechnungMit.kuerzel});
                    }
                    var rowNode = this.gridApi.getRowNode(airline.kundennummer);
                    rowNode.setData(airline);
                });
        }

    }

    createNew() {
        const state = this.state;

        const itemToEdit = this.getEmptyAirline();

        Object.assign(state, {itemToEdit: itemToEdit, createNew: true});
        this.setState(state);
        this.refKuNr.focus();
    }


    getEmptyAirline() {
        return {
            kundennummer: "",
            kuerzel: "",
            ustId: "",
            stnr: "",
            name: "",
            adresse: "",
            kontakt: "",
            strassePostfach: "",
            plz: "",
            ort: "",
            kontrollListe: false,
            rechnungslimit: "",
            aktiv: false,
            hatReferenznummer: false,
            staat: "",
            fremdabrechnungMit: ""
        };
    }


    render() {

        const itemToEdit = this.state.itemToEdit;


        return (
            <div className="fgs-tab-panel fgs-airline-panel"
                 style={{overflow:'hidden', flexGrow: 1}}>
                <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <div style={{flex: '3'}} className="ag-theme-blue">
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.airlines}
                            rowSelection='single'
                            onSelectionChanged={this.rowClicked.bind(this)}
                            frameworkComponents={this.state.frameworkComponents}
                            navigateToNextCell={this.navigateToNextCell}
                            getRowNodeId={this.state.getRowNodeId}
                            />
                    </div>
                    <div className="fgs-airline-form" style={{flex: '2'}}>
                            <div className="form-title">
                                <span>Airline bearbeiten</span>
                            </div>

                            <div className="form-body">
                            {itemToEdit &&
                            <div className="form-body">
                                <label className="field-name">aktiv
                                    <input type="checkbox"
                                           name="aktiv"
                                           checked={itemToEdit.aktiv}
                                           onChange={this.toggleChange}
                                    >
                                </input>
                                </label>
                                <fieldset>
                                    <legend>Anschrift</legend>
                                    <label className="field-name">KuNr
                                        <input name="kundennummer"
                                               ref={ref => this.refKuNr = ref}
                                               value={itemToEdit.kundennummer}
                                               readOnly={!this.state.createNew}
                                               onChange={this.onChange}
                                            >
                                        </input>
                                    </label>
                                    <label className="field-name">Kürzel
                                        <input name="kuerzel"
                                               ref={ref => this.refKuerzel = ref}
                                               value={itemToEdit.kuerzel}
                                               readOnly={!this.state.createNew}
                                               onChange={this.onChange}
                                            >
                                        </input>
                                    </label>
                                    <label className="field-name">Airline
                                    <input name="name"
                                           value={itemToEdit.name}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Adresse
                                    <input name="adresse"
                                           value={itemToEdit.adresse}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Kontakt
                                        <input name="kontakt"
                                           value={itemToEdit.kontakt}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Straße/Postfach
                                        <input name="strassePostfach"
                                           value={itemToEdit.strassePostfach}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Plz
                                        <input name="plz"
                                           value={itemToEdit.plz}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Ort
                                        <input name="ort"
                                           value={itemToEdit.ort}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Staat
                                        <input name="staat"
                                           value={itemToEdit.staat}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                </fieldset>

                                <fieldset>
                                    <legend>Abrechnungsdaten</legend>
                                    <label className="field-name">Abrechnung mit
                                        <select name="fremdabrechnungMit"
                                           value={itemToEdit.fremdabrechnungMit}
                                           onChange={this.onChange}
                                        >
                                        <option value="">- Selbstabrechner -</option>
                                        <option value="13130">LH2 (13130)</option>
                                        <option value="10210">FAG-S (10210)</option>
                                        <option value="22000">FAG-2 (22000)</option>

                                    </select>
                                    </label>
                                    <label className="field-name">Limit
                                        <input name="rechnungslimit"
                                           value={itemToEdit.rechnungslimit}
                                           onChange={this.onChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Kontrollstreifen
                                        <input
                                            type="checkbox"
                                            name="kontrollListe"
                                            checked={itemToEdit.kontrollListe}
                                            onChange={this.toggleChange}
                                        >
                                    </input>
                                    </label>
                                    <label className="field-name">Ref.-Nr.
                                        <input
                                            name="hatReferenznummer"
                                            type="checkbox"
                                            checked={itemToEdit.hatReferenznummer}
                                            onChange={this.toggleChange}
                                        >
                                    </input>
                                    </label>
                                </fieldset>
                                <button
                                    name='submit'
                                    ref={ref => this.refSubmit = ref}
                                    onClick={() => this.onSubmit()}
                                    >Speichern
                                </button>
                                { !this.state.createNew &&
                                <button
                                    name='new'
                                    onClick={() => this.createNew()}
                                    >Neue Airline anlegen
                                </button>
                                }
                                { this.state.createNew &&
                                <button
                                    name='cancel'
                                    onClick={() => this.rowClicked()}
                                    >Abbrechen
                                </button>
                                }
                        </div>
                        }
                        </div>
                    </div>
                </div>
                <div style={{flex:'0 0 60px'}}>&nbsp;</div>
            </div>
        );
    }
}

export default AirlinesGrid;
